import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { TICK_IMG } from "pr-utility/images/index";
import * as actions from "./Actions";
import {
  dispatcherAcceptJobOffer,
  jobOfferAccept,
} from "../../../../pr-home/src/screens/JobOffer/Actions";
import InfiniteScroll from "react-infinite-scroll-component";
import AlertMessage from "../CommonComponent/AlertMessage";
import { FormattedMessage } from "react-intl";
import Header from "../CommonComponent/Header";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
import { reInitDispatcherApp } from "pr-utility/utils/utils";

class SelectTruck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTruckId: null,
      truckUid: null,
      truckList: [],
      showAlert: false,
      alertData: {},
      noDataBlock: false,
      limit: Math.ceil(window.innerHeight / 52) + 3,
      offset: 0,
      hasMoreData: true,
      from: props.location?.state?.from ?? "",
      truckInfo: {},
      jobNumber: props?.history?.location?.state?.jobNumber ?? "",
      jobAcceptData: props?.history?.location?.state?.jobAcceptData ?? "",
    };

    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    if (this.state.from) {
      this.props.history.push("/home");
    } else {
      this.props.history.push("/user");
    }
  }

  saveTruck = async () => {
    await this.props.dispatch(
      actions.selectDispatcherTruck(this.state.truckInfo)
    );

    this.dispatcherAcceptJob();
  };

  dispatcherAcceptJob = async () => {
    this.props.dispatch(actions.isLoading(true));

    const { selectedTruck } = this.props;

    const { jobNumber: jobID, jobAcceptData } = this.state;

    const response = await dispatcherAcceptJobOffer(
      selectedTruck.uid,
      jobID,
      1201,
      jobAcceptData
    );

    if (response && response.status === 200) {
      const logParams = {
        eventId: EventId.JOB_OFFER_ACCEPTED,
        jobID,
      };

      LogAllEvent(logParams);

      this.props.dispatch(jobOfferAccept(true));

      this.setState({ showAlert: false });
    } else if (response.code === "ECONNABORTED") {
      this.props.dispatch(actions.isLoading(false));

      const logParams = {
        eventId: EventId.JOB_OFFER_FAILED_TO_ACCEPT,
        jobID,
      };

      LogAllEvent(logParams);

      this.showAlertModal(true, response, this.acceptJobOffer);
    } else if (response === undefined) {
      this.props.dispatch(actions.isLoading(false));

      const logParams = {
        eventId: EventId.JOB_OFFER_FAILED_TO_ACCEPT,
        jobID,
      };

      LogAllEvent(logParams);

      this.showAlertModal(true);

      this.setState({ jobActionStarted: false });
    } else {
      this.props.dispatch(actions.isLoading(false));

      const logParams = {
        eventId: EventId.JOB_OFFER_FAILED_TO_ACCEPT,
        jobID,
      };

      LogAllEvent(logParams);

      this.showAlertModal(false, response);

      this.setState({ jobActionStarted: false });
    }
  };

  selectTruck = (truck) => {
    this.setState({
      selectedTruckId: truck.truckInfo.id,
      truckUid: truck.truckInfo.uid,
      truckInfo: truck.truckInfo,
    });
  };

  showAlertModal = (netWorkError, response, callback, params) => {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    }
    this.setState({ showAlert: true, alertData });
  };
  componentDidMount() {
    const { Authorization } = this.props;

    let obj = Authorization;

    if (isEmpty(obj)) {
      reInitDispatcherApp();
    } else {
      this.loadVehicles();
    }
  }

  loadVehicles = async () => {
    await this.getVehicleList();
  };

  fetchMoreData = async () => {
    this.setState({ offset: this.state.offset + this.state.limit });

    this.props.dispatch(actions.isLoading(true));

    let response = await actions.getVehicleListForDispatcher(
      this.state.limit,
      this.state.offset
    );

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      if (response.data.data.length > 0) {
        let truckAppendedData = this.state.truckList.concat(response.data.data);

        this.setState({
          truckList: truckAppendedData,
          hasMoreData: true,
        });
      } else {
        this.setState({ offset: 0, hasMoreData: false });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.fetchMoreData);
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
    }
  };

  getVehicleList = async () => {
    this.props.dispatch(actions.isLoading(true));

    const { entityType } = this.props;

    let response = await actions.getVehicleListForDispatcher(
      this.state.limit,
      this.state.offset
    );

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      let truckData = response.data.data;

      this.setState({
        truckList: truckData,
        noDataBlock: !(response.data.data.length > 0),
      });
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getVehicleList);
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
    }

    if (entityType === "DISPATCHER") {
      let windowHeight = window.innerHeight;
      let sDiv = document.getElementById("scrollableDiv");

      if (sDiv) {
        sDiv.style.height = windowHeight - 185 + "px";
      }
    }
  };

  renderTruckList = (truckList) => {
    if (!this.state.noDataBlock) {
      const { entityType, DispatcherData } = this.props;

      let vehicleManagementEnabled =
        DispatcherData?.dispatcherDetail?.providerPreference
          ?.vehicleManagementEnabled;

      return truckList.map((truck, idx) => {
        return (
          <li key={truck.truckInfo.id}>
            {process.env.REACT_APP_COUNTRY === "US" ? (
              <span
                className="text dark"
                onClick={() => this.selectTruck(truck)}
              >
                {!vehicleManagementEnabled &&
                  entityType === "DISPATCHER" &&
                  truck.driverInfo &&
                  `${truck.driverInfo.firstName} ${truck.driverInfo.lastName}`}
                {vehicleManagementEnabled &&
                  entityType === "DISPATCHER" &&
                  truck.truckInfo &&
                  `${truck.truckInfo.externalId || truck.truckInfo.id} (${
                    truck.truckInfo.truckType
                  })`}
              </span>
            ) : (
              <span
                className="text dark"
                onClick={() => this.selectTruck(truck)}
              >
                {truck.truckInfo.name || truck.truckInfo.id}
                {/*truck.truckInfo.name*/}
              </span>
            )}
            {this.state.selectedTruckId === truck.truckInfo.id ? (
              <span className="tick">
                <img src={TICK_IMG} title="" alt="" />
              </span>
            ) : (
              ""
            )}
          </li>
        );
      });
    } else {
      return (
        <div className="jobList">
          <div className="dataRow ">
            <div className="head">
              <FormattedMessage id="jobs_list.not_found" />
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const { DispatcherData } = this.props;
    let { vehicleManagementEnabled } =
      DispatcherData?.dispatcherDetail?.providerPreference || true;

    let page_header = "",
      wrapper_class = "";
    if (process.env.REACT_APP_COUNTRY === "US") {
      page_header = vehicleManagementEnabled ? (
        <FormattedMessage id="profile.select_truck" />
      ) : (
        <FormattedMessage id="profile.select_driver" />
      );
      wrapper_class = "parent_us";
    }

    let truckList = this.state.truckList;
    let subTitle = vehicleManagementEnabled ? (
      <FormattedMessage id="profile.trucks" />
    ) : (
      <FormattedMessage id="profile.drivers" />
    );
    /*eslint-disable no-script-url*/
    return (
      <div className={`pg bgGray ${wrapper_class}`}>
        <Header title={page_header} />
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
        {this.state.truckList.length && process.env.REACT_APP_COUNTRY === "US" && (
          <div id="truckWrapper" className={`content pb20`}>
            <h2 className="listViewTitle">{subTitle}</h2>
            <div id="scrollableDiv" className={`trucksWrap`}>
              <div className="listViewBox ">
                <InfiniteScroll
                  dataLength={this.state.truckList.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.hasMoreData}
                  scrollableTarget="scrollableDiv"
                  endMessage=""
                >
                  <ul className="type3"> {this.renderTruckList(truckList)}</ul>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        )}
        {this.state.truckList.length && process.env.REACT_APP_COUNTRY === "US" && (
          <footer className="bottom_fix bgGray">
            <div className="container clearfix pl-0 pr-0 text-center">
              <button
                className={
                  "btn mb0 " + (this.state.selectedTruckId ? "" : "disabled")
                }
                onClick={this.saveTruck}
              >
                <FormattedMessage id="profile.save" />
              </button>
            </div>
          </footer>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entityUid, entityType } = state.Authorization.authorization;

  const { personalConfig, networkConfig, truckDetail, driverDetail } =
    state.DriverData;

  const { selectedTruck } = state.Truck;

  const location = state.Location;

  return {
    location,
    entityUid,
    entityType,
    truckDetail,
    driverDetail,
    selectedTruck,
    networkConfig,
    personalConfig,
    Authorization: state.Authorization.authorization,
    DriverData: state.DriverData,
    TruckData: state.Truck,
    AppConfig: state.AppConfig,
    DispatcherData: state.DispatcherData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SelectTruck)
);
