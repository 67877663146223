import ApiConfigs from "../../config/client";

export const providerManagement = (mobileNumber) => {
  return `${ApiConfigs.HOST_URL}/provider-management/${mobileNumber}`;
};
export const driverManagement = (mobileNumber, provider) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/${mobileNumber}?providerId=${provider}`;
};
export const getAllService = (provider, driver) => {
  return `${ApiConfigs.HOST_URL}/personnel/urgently:${provider}:${driver}/services`;
};

export const declinedJobData = (driverId) => {
  return `${ApiConfigs.US.DEV_CONFIG}/personnel/cancel/reasons?id=${driverId}`;
};

export const driverPhoto = (provider, driver) => {
  return `${ApiConfigs.HOST_URL}/personnel/urgently:${provider}:${driver}/photo`;
};

export const activateCode = () => {
  return `${ApiConfigs.AUTH_URL}`;
};

export const appEvent = () => {
  return `${ApiConfigs.HOST_URL}/events`;
};

export const dispatcherAppEvent = () => {
  return `${ApiConfigs.HOST_URL}/events/dispatcher-app-events`;
};

export const refreshToken = () => {
  return `${ApiConfigs.AUTH_URL}/provider-entities/auth/refresh`;
};

export const mobileNumberOTP = (entityId, mobileNumber) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/initiate-signup?driverId=${entityId}&phone=${mobileNumber}`;
};

export const verifyOTP = (mobileNumber, code) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/validate-signup?phone=${mobileNumber}&code=${code}`;
};

export const getAllTechnicians = (technicianUid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/drivers?id=${technicianUid}&limit=100&offset=0`;
};

export const verifyTechnicianPassword = (technicianUid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/verify/${technicianUid}`;
};

export const updateTechnicianInfo = (technicianUid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${technicianUid}`;
};

export const initiateOTPRequest = () => {
  return `${ApiConfigs.HOST_URL}/phone-verification/send-code`;
};

export const validateOTP = () => {
  return `${ApiConfigs.HOST_URL}/phone-verification/validate-code`;
};
//TO-DO make urgently dynamic
