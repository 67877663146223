import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Cleave from "cleave.js/dist/cleave-react";
import { FormattedMessage } from "react-intl";
import {
  countryMobileLength,
  mobileNumberFormat,
} from "pr-utility/utils/utils";
import client from "pr-utility/config/client";
import * as actions from "./Actions";
import AlertMessage from "../CommonComponent/AlertMessage";
import Header from "../CommonComponent/Header";
import { logo_urgently_dark } from "pr-utility/images/index";
import notification from "pr-utility/utils/notification";
import { Events } from "pr-utility/event-manager/event";
import { EventList } from "pr-utility/event-manager/event-list";
import { postType } from "pr-utility/utils/postMessage";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { onBlurEvent, scroll } from "pr-utility/utils/utils";
//import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
class DriverVerification extends Component {
  // TO-DO after login done inform native about status- use when coming from background
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      phoneNumberEntered: false,
      country: client.PHONE_CODE,
      phoneLength: countryMobileLength[client.DEFAULT_COUNTRY_CODE],
      blocks: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].blocks,
      delimiters: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].delimiters,
      numericOnly: mobileNumberFormat[client.DEFAULT_COUNTRY_CODE].numericOnly,
      creditCardCleave: "",
      activateEnabled: false,
      firstName: "",
      lastName: "",
      showAlert: false,
      alertData: {},
      recievedData: {},
      driverPhotoUrl: "",
      driverInfo: {},
      truckInfo: {},
      providerName: "",
      driverImageState: null,
    };
    this.editProfile = false;
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.verifyDriverNumber = this.verifyDriverNumber.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.goToCheckIn = this.goToCheckIn.bind(this);
    this.getServiceList = this.getServiceList.bind(this);
    this.getCancelReason = this.getCancelReason.bind(this);
    this.saveInfo = this.saveInfo.bind(this);
    this.onNumberChange = this.onNumberChange.bind(this);

    this.showAlertModal = this.showAlertModal.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.setInitialVars();
  }

  setInitialVars() {
    let page = window.location.hash;
    if (page.indexOf("profile") !== -1) {
      this.editProfile = true;
    }
  }

  addPhotoClick = () => {
    let obj = {
      type: postType.ADD_PHOTO,
      dto: {
        data: null,
        error: false,
        errorMessage: "",
        cameraOpen: true,
      },
    };
    notification.postMessage(obj);
  };

  onPhoneNumberChange(event) {
    if (this.state.phoneNumber === event.target.rawValue) {
      return;
    }
    this.setState({ activateEnabled: false });
    if (
      event.target.value !== "" &&
      event.target.value.length !== 0 &&
      event.target.value.length === this.state.phoneLength &&
      !this.state.phoneNumberEntered &&
      this.state.phoneNumber !== event.target.rawValue
    ) {
      this.setState({ phoneNumber: event.target.rawValue });
      this.setState({ phoneNumberEntered: true });

      this.verifyDriverNumber(event.target.rawValue);
    } else {
      this.setState({ phoneNumber: event.target.rawValue });
      this.setState({ phoneNumberEntered: false });
      this.setState({ activateEnabled: false });
    }
  }

  async verifyDriverNumber(phoneNumber) {
    const { providerPhoneNumber } = this.props;

    this.props.dispatch(actions.isLoading(true));

    let data = {
      envId: "1", //TO-DO dynamic
      environment: "dev", //TO-DO dynamic
      companyPhone: providerPhoneNumber,
      driverPhone: phoneNumber,
      version: "3.0.0",
    };

    const response = await actions.driverVerify(
      phoneNumber,
      providerPhoneNumber,
      data
    );

    if (response && Number(response.status) === 200) {
      let personalInfo = response.data.data[0].personalInfo;
      let driverInfo = response.data.data[0].driverInfo;
      this.setState({
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        activateEnabled: true,
      });
      let serviceList = await this.getServiceList(
        driverInfo.providerId,
        driverInfo.id
      );
      if (serviceList && Number(serviceList.status) === 200) {
        this.props.dispatch(
          actions.driverVerificationSuccess(response.data.data[0])
        );
        this.props.dispatch(
          actions.getAllServiceListSuccess(serviceList.data.data)
        );
        this.setState({ driverPhotoUrl: "", recievedData: {} });
        this.getDriverPhoto();
        this.props.dispatch(actions.isLoading(false));
      } else if (serviceList === undefined) {
        this.showAlertModal(true);
        this.props.dispatch(actions.isLoading(false));
      } else {
        this.showAlertModal(false, response);
        this.props.dispatch(actions.isLoading(false));
      }
      this.props.dispatch(actions.isLoading(true));
      let cancelReason = await this.getCancelReason(driverInfo.id);
      if (cancelReason && Number(cancelReason.status) === 200) {
        this.props.dispatch(
          actions.cancelReasonSuccess(cancelReason.data.data)
        );
        this.props.dispatch(actions.isLoading(false));
      }
    } else if (response.code === "ECONNABORTED") {
      this.showAlertModal(true, response, this.verifyDriverNumber, phoneNumber);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      this.showAlertModal(true);

      this.setState({ firstName: "", lastName: "" });
      this.props.dispatch(actions.isLoading(false));
    } else {
      this.showAlertModal(false, response);
      this.setState({ firstName: "", lastName: "" });
      this.props.dispatch(actions.isLoading(false));
    }
  }

  showAlertModal = (netWorkError, response, callback, params) => {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.driver_not_activated" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    }
    this.setState({ showAlert: true, alertData: alertData });
  };

  async getServiceList(driverPhone, providerPhone) {
    const response = await actions.getAllServiceList(
      driverPhone,
      providerPhone
    );
    return response;
  }
  async getCancelReason(driverid) {
    const response = await actions.getCancelReason(driverid);
    return response;
  }

  onFirstNameChange(ev) {
    this.setState({ firstName: ev.target.value });
  }
  onLastNameChange(ev) {
    this.setState({ lastName: ev.target.value });
  }
  goBack() {
    if (this.editProfile) {
      Events.trigger(EventList.home.settings, {});
    } else {
      Events.trigger(EventList.init.login, {});
    }
  }

  goToCheckIn() {
    const { driverInfo } = this.props;
    // satus is checkin or busy  got to home directly
    if (driverInfo.status === 3 || driverInfo.status === 1) {
      this.sendMessage();
      Events.trigger(EventList.login.success, {});
    } else {
      Events.trigger(EventList.home.check_in, {});
    }
  }

  sendMessage() {
    const { AppConfig, Provider, Driver } = this.props;

    if (AppConfig) {
      let data = {
        config: AppConfig.configs,
        config_keys: AppConfig.config_keys,
        provider_info: Provider,
        driver_info: Driver,
      };

      let userData = {
        type: postType.USER_SESSION,
        dto: {
          data: data,
          error: false,
          errorMessage: "",
        },
      };

      notification.postMessage(userData);
    }
  }

  componentDidMount() {
    this.getEntityDetail();

    Events.on(
      EventList.native.add_photo,
      "individual_flow",
      this.sendEditedPhoto
    );
  }

  getEntityDetail = async () => {
    this.props.dispatch(actions.isLoading(true));
    const response = await actions.getEntityDetailAction(this.props.entityUid);

    this.props.dispatch(actions.isLoading(false));
    if (response && Number(response.status) === 200) {
      if (response.data.data[0]) {
        this.props.dispatch(actions.isLoading(false));
        this.setState({
          driverInfo: response.data.data[0].driverInfo,
          phoneNumber: response.data.data[0].driverInfo.phoneNumber,
          providerName: response.data.data[0].provider.name,
          truckInfo: response.data.data[0].truckInfo,
          driverImageState: `${
            response.data.data[0].driverInfo.picture
          }?d=${new Date().getTime()}`,
        });
      } else {
        this.showAlertModal(false, {
          error: "An error has occurred.",
        });
        sendErrorLog({
          data: {
            error: "An error has occurred on getEntityDetail detail.",
          },
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getEntityDetail);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  getDriverPhoto = async () => {
    this.props.dispatch(actions.isLoading(true));
    const { driverInfo } = this.props;

    let response = await actions.getDriverPhoto(
      driverInfo.providerId,
      driverInfo.id
    );

    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.isLoading(false));
      this.setState({ driverPhotoUrl: response.data.data[0] });
    } else if (response.code === "ECONNABORTED") {
      this.showAlertModal(true, response, this.getDriverPhoto);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  sendEditedPhoto = async (recievedData) => {
    this.props.dispatch(actions.isLoading(true));
    this.setState({ recievedData });

    let imageData = `data:image/jpg+xml;base64,${recievedData.data}`;
    const data = new FormData();
    let arrayValue = [];
    try {
      let binary = atob(imageData.split(",")[1]);
      for (let i = 0; i < binary.length; i++) {
        arrayValue.push(binary.charCodeAt(i));
      }

      let payload = new Blob([new Uint8Array(arrayValue)], {
        type: "image/jpg",
      });
      data.append("photo", payload);
    } catch (er) {
      sendErrorLog({
        data: {
          error: er,
        },
      });
    }

    let response = await actions.uploadDriverPhoto(this.props.entityUid, data);

    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.isLoading(false));
    } else if (response.code === "ECONNABORTED") {
      this.showAlertModal(true, response, this.sendEditedPhoto, recievedData);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  saveInfo = async () => {
    if (
      this.state.phoneLength !==
      this.state.phoneNumber.length + this.state.delimiters.length
    ) {
      return;
    }
    let data = {
      driverInfo: {
        phoneNumber: this.state.phoneNumber,
      },
    };
    this.props.dispatch(actions.isLoading(true));
    var response = await actions.updateDriverInfo(this.props.entityUid, data);
    if (response && Number(response.status) === 200) {
      if (response.data & response.data.data) {
        this.props.dispatch(
          actions.updateDriverDetailSuccess(response.data.data[0])
        );
      }
      this.props.dispatch(actions.isLoading(false));
    } else if (response && response.code === "ECONNABORTED") {
      this.showAlertModal(true, response, this.saveInfo);
      this.props.dispatch(actions.isLoading(false));
    } else if (response === undefined) {
      this.showAlertModal(true);
      this.props.dispatch(actions.isLoading(false));
    } else {
      this.showAlertModal(false, response);
      this.props.dispatch(actions.isLoading(false));
    }
  };

  onNumberChange = (event) => {
    if (this.state.phoneNumber === event.target.rawValue) {
      return;
    }
    this.setState({ activateEnabled: false });
    if (
      event.target.value !== "" &&
      event.target.value.length !== 0 &&
      event.target.value.length === this.state.phoneLength
    ) {
      this.setState({
        activateEnabled: true,
        phoneNumber: event.target.rawValue,
      });
    } else {
      this.setState({ phoneNumber: event.target.rawValue });
    }
  };

  render() {
    let driverImage = this.state.recievedData.data || "";
    let driverImageState = this.state.driverImageState;
    let phoneLabel = <FormattedMessage id="profile.phone_number" />;
    let vehicleLabel = <FormattedMessage id="profile.vehicle" />;
    /*eslint-disable no-script-url*/
    let pageHeader = <FormattedMessage id="profile.profile" />;
    let saveText = <FormattedMessage id="profile.save" />;

    let photoLink;
    if (this.editProfile) {
      process.env.REACT_APP_COUNTRY === "AU"
        ? (photoLink = <FormattedMessage id="profile.edit_photo" />)
        : driverImageState &&
          !driverImage &&
          driverImageState.indexOf("http") === -1
        ? (photoLink = <FormattedMessage id="profile.add_photo" />)
        : (photoLink = <FormattedMessage id="profile.edit_photo" />);
    } else {
      photoLink = <FormattedMessage id="profile.add_photo" />;
    }

    let wrapper_class;
    if (process.env.REACT_APP_COUNTRY === "US") {
      wrapper_class = "parent_us";
    }

    return (
      <div className={`pg ${wrapper_class}`}>
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
        {!this.editProfile && (
          <Header title={pageHeader} left="true" backButton={this.goBack} />
        )}
        {this.editProfile && process.env.REACT_APP_COUNTRY === "AU" && (
          <Header
            title={pageHeader}
            left="true"
            right="true"
            backButton={this.goBack}
            rightButtonText={saveText}
            rightButton={this.saveInfo}
          />
        )}
        {this.editProfile && process.env.REACT_APP_COUNTRY === "US" && (
          <Header title={pageHeader} left="true" backButton={this.goBack} />
        )}

        {/* <Loader loaded={!loaded} /> */}
        {this.state.driverInfo.firstName && (
          <div className="content">
            <div className="">
              <div className="clearfix" />
              <div className="gap10" />
              <h2 className="pgTitle mt5 mb0 text-center">
                {this.state.driverInfo.firstName
                  ? this.state.driverInfo.firstName +
                    " " +
                    this.state.driverInfo.lastName
                  : ""}
                &nbsp;
              </h2>
              {process.env.REACT_APP_COUNTRY === "US" &&
              this.state.driverInfo.phoneNumber ? (
                <div className="text-center">
                  <Cleave
                    value={this.state.driverInfo.phoneNumber}
                    options={{
                      blocks: this.state.blocks,
                      delimiters: this.state.delimiters,
                      numericOnly: this.state.numericOnly,
                    }}
                    className="txtBox userDetailstext fontwtb driverPhone"
                    required
                    disabled={true}
                  />
                  <p className="userDetailstext">{this.state.providerName}</p>
                </div>
              ) : (
                ""
              )}

              <div className="clearfix" />
              <div className="profile_box">
                <div className="profilePic">
                  <div className="img">
                    {driverImage && (
                      <div className="img">
                        <div
                          className="imgInner active"
                          style={{
                            background:
                              "url(data:image/jpg+xml;base64," +
                              driverImage +
                              ")",
                            backgroundSize: "cover",
                            backgroundPosition: "50% 50%",
                          }}
                        />
                      </div>
                    )}
                    {driverImageState && !driverImage && (
                      <div className="img">
                        <div
                          className="imgInner active"
                          style={{
                            background: "url(" + driverImageState + ")",
                            backgroundSize: "cover",
                            backgroundPosition: "50% 50%",
                          }}
                        />
                      </div>
                    )}
                    {!driverImageState && !driverImage && (
                      <div className="img" />
                    )}
                  </div>
                  <span
                    href="javascript:void(0)"
                    className={`lnk`}
                    onClick={() => this.addPhotoClick()}
                  >
                    {photoLink}
                  </span>
                </div>
              </div>
              {process.env.REACT_APP_COUNTRY === "AU" && (
                <h2 className="listViewTitle fontSize18">{phoneLabel}</h2>
              )}
              {process.env.REACT_APP_COUNTRY === "US" && (
                <h2 className="listViewTitle fontSize18">{vehicleLabel}</h2>
              )}

              <div className="listViewBox">
                <ul className="type1">
                  {process.env.REACT_APP_COUNTRY === "AU" && (
                    <li>
                      <span className="text">
                        <Cleave
                          value={this.state.phoneNumber}
                          options={{
                            blocks: this.state.blocks,
                            delimiters: this.state.delimiters,
                            numericOnly: this.state.numericOnly,
                          }}
                          className="txtBox"
                          id="cardNum"
                          placeholder="e.g. 5555 555 555"
                          type="tel"
                          autoComplete="off"
                          maxLength={this.state.phoneLength}
                          onChange={this.onNumberChange}
                          onFocus={() => scroll(".content")}
                          onClick={() => scroll(".content")}
                          onBlur={onBlurEvent}
                          required
                        />
                      </span>
                      <span className="arrow d-none">
                        <i className="icon ion-ios-arrow-right" />
                      </span>
                    </li>
                  )}
                  {process.env.REACT_APP_COUNTRY === "US" &&
                    this.state.truckInfo &&
                    this.state.truckInfo.name &&
                    this.state.truckInfo.truckType && (
                      <li>
                        <span className="text">
                          {`${this.state.truckInfo.name} (${this.state.truckInfo.truckType})`}{" "}
                          &nbsp;
                        </span>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        )}
        {!this.editProfile && (
          <footer className="bottom_fix">
            <div className="container clearfix pl-0 pr-0 text-center">
              <span
                href="javascript:void(0)"
                className={
                  "btn " + (this.state.activateEnabled ? "" : "disabled")
                }
                onClick={this.goToCheckIn}
              >
                <FormattedMessage id="profile.activate" />
              </span>
            </div>
          </footer>
        )}
        {this.editProfile && process.env.REACT_APP_COUNTRY === "AU" && (
          <div className="bottom_fix text-center pb40">
            <div className="about_logo">
              <img src={logo_urgently_dark} title="" alt="" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entityUid, entityType } = state.Authorization.authorization;

  const { driverDetail } = state.DriverData;

  const { providerPhoneNumber } = state.Provider;

  const { driverInfo } = driverDetail || {};

  return {
    entityUid,
    entityType,
    driverInfo,
    providerPhoneNumber,
    DriverData: state.DriverData,
    TruckData: state.Truck,
    AppConfig: state.AppConfig,
    Provider: state.Provider,
    Driver: state.Driver,
    Authorization: state.Authorization.authorization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DriverVerification)
);
