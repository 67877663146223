export const driverAction = {
  ACCEPTED_BY_PROVIDER: 1201,
  REJECTED_BY_PROVIDER: 1202,
  CANCELLED_BY_PROVIDER: 1203,
  COMPLETED_BY_PROVIDER: 1204,
  GOA_BY_PROVIDER: 1205,
  PROVIDER_DETAILS_UPDATE: 1206,
  PROVIDER_LEFT_FOR_JOB: 1207,
  PROVIDER_REACHED_JOB_SITE: 1208,
  PROVIDER_REACHING_JOB_SITE: 1209,
  PROVIDER_LEFT_JOB_SITE: 1210,
};

export const JobState = {
  // JOB_STATUS
  JOB_STATUS_NEW: 0,
  JOB_STATUS_FOUND_PROVIDERS: 1,
  JOB_STATUS_CONTACTING_PROVIDERS: 2,
  JOB_STATUS_ASSIGNED: 3,
  JOB_STATUS_PROVIDER_ON_WAY: 4,
  JOB_STATUS_PROVIDER_ARRIVED: 5,
  JOB_STATUS_CC_AUTHORIZED: 6,
  JOB_STATUS_CC_VOIDED: 7,
  JOB_COMPLETED: 6,
  JOB_STATUS_PROVIDER_REACHING_SITE: 10,
  JOB_STATUS_PROVIDER_LEFT_SITE: 11,
  JOB_STATUS_NO_CC_ONLINE: 88,
  JOB_STATUS_PAYMENT_FAILED: 89,
  JOB_STATUS_ALREADY_COMPLETE: 90,
  JOB_STATUS_SYSTEM_ENTERED: 101,
  JOB_STATUS_POTENTIAL_ONLY: 200,
  JOB_STATUS_TEST_ONLY: 300,
  /* JOB expiry related status code */
  JOB_STATUS_EXPIRED: 24,
  JOB_STATUS_NOT_FOUND_PROVIDERS: 20,
  JOB_STATUS_COMPLETED_BY_PROVIDER_GOA: 25,
  JOB_STATUS_CLOSED_GOA_NO_PAYMENT: 93,
  JOB_STATUS_NO_CC_ON_FILE: 99,
  /* JOB cancel related status code. */
  JOB_STATUS_CANCELLED_BY_CUSTOMER: 21,
  JOB_STATUS_CANCELED_BY_PROVIDER: 9,
  JOB_STATUS_CANCELED_BY_PROVIDER_FOR_CUSTOMER: 22,
  JOB_STATUS_PROVIDER_CANCEL_BY_CONSUMER_REQUEST: 97,
  /* Receipt related status code */
  JOB_STATUS_COMPLETE_BY_PROVIDER: 8,
  JOB_STATUS_CLOSED_AS_EXPIRED: 91,
  JOB_STATUS_CLOSED_ALL_PROVIDER_REJECTED: 96,
  JOB_STATUS_CLOSED: 100,
  JOB_STATUS_CLOSED_PAID_BY_CASH: 92,
  JOB_STATUS_CLOSED_NO_PAYMENT_NEEDED: 98,
  JOB_STATUS_CLOSED_GOA: 99,
  /* Tow Exchange (Double Tow) */
  JOB_STATUS_TOW_EXCHANGE: 2068,
};

export const serviceType = {
  TOW: "TOW",
};

export const DEFAULT_MIN_ETA_VALUE = 10;

export const DEFAULT_MAX_ETA_VALUE = 180;

/**
 * @enum {string}
 */
export const FuelType = {
  Gasoline: "Gasoline",
  Electric: "Electric",
  Diesel: "Diesel",
};
