const loadTranslation = () => {
  let lang;
  if (process.env.REACT_APP_COUNTRY === "AU") {
    lang = require("pr-utility/translations/en.json");
  } else if (process.env.REACT_APP_COUNTRY === "US") {
    lang = require("pr-utility/translations/en_US.json");
  }
  return lang;
};
module.exports = {
  lang: loadTranslation(),
};
