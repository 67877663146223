import { store } from "../../store";
import { postType } from "pr-utility/utils/postMessage";
import notification from "pr-utility/utils/notification";
import client from "pr-utility/config/client";
import * as actions from "./Actions";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";

export const authConfig = {
  refreshTokenProgress: null,
};

export async function checkAuthorization() {
  let expirationCheckTime = 10; /// in minutes
  let storeStates = store.getState();
  if (
    storeStates.Authorization &&
    storeStates.Authorization.authorization &&
    storeStates.Authorization.authorization.accessToken
  ) {
    let currentTime = new Date().getTime();
    let actualExpirationTime =
      storeStates.Authorization.authorization.expirationTime;
    let securityTokenExpirationTime =
      actualExpirationTime - expirationCheckTime * 60 * 1000;
    // first condition will check for expirationCheckTime
    // second condition will check if token already expire
    if (
      (securityTokenExpirationTime <= currentTime &&
        currentTime <= actualExpirationTime) ||
      currentTime >= actualExpirationTime
    ) {
      await refreshToken();
      authConfig.refreshTokenProgress = null;
      return false;
    } else {
      return true;
    }
  }
}

export async function refreshToken() {
  if (!authConfig.refreshTokenProgress) {
    authConfig.refreshTokenProgress = new Promise(async (resolve, reject) => {
      const response = await actions.refreshToken(
        store.getState().Authorization.authorization
      );
      if (response) {
        if (response.accessToken) {
          client.setAuthToken(response.accessToken);
          let expirationTime = new Date().getTime() + response.expiresIn * 1000;
          response.expirationTime = expirationTime;
          store.dispatch(await actions.refreshTokenSuccess(response));
          let data = {
            request: store.getState(),
            response: response,
          };
          let logData = {
            data: {
              error: JSON.stringify({
                data: data,
                message: "refreshtoken=====refresh token received",
              }),
            },
          };
          sendErrorLog(logData);
          let authData = {
            type: postType.REFRESH_TOKEN,
            dto: {
              data: {
                Authorization: {
                  ...response,
                  code: store.getState().Authorization.authorization.code,
                },
              },
              error: false,
              errorMessage: "",
            },
          };
          notification.postMessage(authData);
          resolve();
        } else {
          let data = {
            request: store.getState().Authorization.authorization,
            response: response,
          };
          let logData = {
            data: {
              error: JSON.stringify({
                data: data,
                message:
                  "refreshtoken=====called refresh token and not received access token",
              }),
            },
          };
          sendErrorLog(logData);
          let userData = {
            type: postType.LOGOUT,
            dto: {
              data: {},
              error: false,
              errorMessage: "",
            },
          };
          notification.postMessage(userData);
          resolve();
        }
      } else {
        let data = {
          request: store.getState().Authorization.authorization,
          response: response,
        };
        let logData = {
          data: {
            error: JSON.stringify({
              data: data,
              message:
                "refreshtoken=====called refresh token and not received refresh token respose",
            }),
          },
        };
        sendErrorLog(logData);
        let userData = {
          type: postType.LOGOUT,
          dto: {
            data: {},
            error: false,
            errorMessage: "",
          },
        };
        notification.postMessage(userData);
        resolve();
      }
    });
  }

  return authConfig.refreshTokenProgress;
}
