import ApiConfigs from "../config/client";
import axios from "axios";
import {
  authConfig,
  checkAuthorization,
  refreshToken,
} from "pr-utility/common/Authorization";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
// import {getStore} from "../../store";

const instance = axios.create();
instance.defaults.timeout = 30000;

export function GETAuth(url) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      apiKey: ApiConfigs.ACCESS_TOKEN,
    },
  };
  return instance.get(url, reqHeader);
}

export function GET_V3(url) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      apiKey: ApiConfigs.ACCESS_TOKEN,
    },
  };
  return instance.get(url, reqHeader);
}

export async function GET(url) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${ApiConfigs.AUTH_TOKEN}`,
    },
  };
  let response = instance.get(url, reqHeader);
  return response;
}

export async function POST(url, data) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${ApiConfigs.AUTH_TOKEN}`,
    },
  };
  return instance.post(url, data, reqHeader);
}

export function POST_NO_TOKEN(url, data) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return instance.post(url, data, reqHeader);
}

export function POST_V3(url, data) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      apiKey: ApiConfigs.ACCESS_TOKEN,
    },
  };
  return instance.post(url, data, reqHeader);
}

export async function PUT(url, data) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${ApiConfigs.AUTH_TOKEN}`,
    },
  };
  return instance.put(url, data, reqHeader);
}

export function PUT_V3(url, data) {
  let reqHeader = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      apiKey: ApiConfigs.ACCESS_TOKEN,
    },
  };
  return instance.put(url, data, reqHeader);
}

export async function multipartPost(url, data) {
  let reqHeader = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${ApiConfigs.AUTH_TOKEN}`,
    },
  };
  return instance.post(url, data, reqHeader);
}

instance.interceptors.request.use(
  async function (request) {
    //request.headers.Product  = "Driver";
    if (request && request.headers && "Authorization" in request.headers) {
      let authReponse = await checkAuthorization();
      if (authReponse) {
        return request;
      } else if (authReponse === false) {
        //let storeStates = getStore().getState();
        //await refreshToken();
        request.headers.Authorization = "Bearer " + ApiConfigs.AUTH_TOKEN;
        return request;
      }
    } else {
      return request;
    }
    // return response;
  },
  async function (error) {}
);
instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.includes("Token has expired")
    ) {
      let logData = {
        data: {
          error: JSON.stringify({
            data: error.response,
            message: "refreshtoken=====auth token expired error",
          }),
        },
      };
      sendErrorLog(logData);
      let errorResponse = error.response;
      await refreshToken();
      authConfig.refreshTokenProgress = null;
      errorResponse.config.__isRetryRequest = true;
      errorResponse.config.headers["Authorization"] =
        "Bearer " + ApiConfigs.AUTH_TOKEN;
      logData = {
        data: {
          error: JSON.stringify({
            data: error.response,
            message:
              "refreshtoken=====received new token and now calling faild API",
          }),
        },
      };
      sendErrorLog(logData);
      return axios(errorResponse.config);
    } else {
      let logData = {
        data: {
          error: JSON.stringify({
            data: error,
            response: error.response
              ? error.response
              : "not received error response",
            message: "refreshtoken=====API failed and throw error",
          }),
        },
      };
      sendErrorLog(logData);
      throw error;
    }
  }
);
// Add a response interceptor
/*
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Do something with response error
  if(error.code == 'ECONNABORTED'){
    Swal({
      title: "",
      animation: false,
      text: 'Unable to complete request.',
      showCancelButton: true,
      confirmButtonText: "Try Again"
    }).then(
        result => {
          console.log(result);
          if (result.dismiss) {
            return false;
          } else {
            instance.request(error.config);
          }
    });
  } else {
    return Promise.reject(error);
  }
});
*/
