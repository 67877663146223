import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  configs: null,
  config_keys: null,
  cancel_reasons: null,
  is_loading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.IS_LOADING: {
      return {
        ...state,
        is_loading: action.bool,
      };
    }
    case types.ON_GET_CONFIG_SUCESS: {
      return {
        ...state,
        configs: action.response,
      };
    }
    case types.ON_GET_CANCEL_REASONS_SUCESS: {
      return {
        ...state,
        cancel_reasons: action.response,
      };
    }
    case types.ON_GET_CONFIG_KEYS_SUCCESS: {
      return {
        ...state,
        config_keys: action.response,
      };
    }
    default:
      return state;
  }
}
