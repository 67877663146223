export const ON_ACTIVATION = "ON_ACTIVATION";
export const PROVIDER_VERIFICATION_SUCCESS = "PROVIDER_VERIFICATION_SUCCESS";
export const ON_GET_CONFIG_SUCESS = "ON_GET_CONFIG_SUCESS";
export const ON_GET_CANCEL_REASONS_SUCESS = "ON_GET_CANCEL_REASONS_SUCESS";
export const IS_LOADING = "IS_LOADING";
export const DRIVER_VERIFICATION_SUCCESS = "DRIVER_VERIFICATION_SUCCESS";
export const PROVIDER_PHONE_NUMBER = "PROVIDER_PHONE_NUMBER";
export const SELECT_TRUCK = "SELECT_TRUCK";
export const SELECT_EQUIPEMENT = "SELECT_EQUIPEMENT";
export const ALL_SERVICE_LIST_SUCCESS = "ALL_SERVICE_LIST_SUCCESS";
export const SELECTED_SERVICES = "SELECTED_SERVICES";
export const ON_GET_CONFIG_KEYS_SUCCESS = "ON_GET_CONFIG_KEYS_SUCCESS";
export const CANCEL_REASON = "ON_GET_CANCEL_REASON_SUCCESS";
export const ON_AUTH_DATA_SUCCESS = "ON_AUTH_DATA_SUCCESS";
export const ON_RESTORE_AUTH_DATA_SUCCESS = "ON_RESTORE_AUTH_DATA_SUCCESS";
export const DRIVER_PHONE_NUMBER = "DRIVER_PHONE_NUMBER";
export const ON_TRUCK_CHECKIN_SUCCESS = "ON_TRUCK_CHECKIN_SUCCESS";
export const INIT_APP_DATA = "INIT_APP_DATA";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const VEHICLE_DETAIL_SUCCESS = "VEHICLE_DETAIL_SUCCESS";
export const DRIVER_DETAIL_SUCCESS = "DRIVER_DETAIL_SUCCESS";
export const ON_TRUCK_CONFIG_SUCCESS = "ON_TRUCK_CONFIG_SUCCESS";
export const ON_DRIVER_CONFIG_SUCCESS = "ON_DRIVER_CONFIG_SUCCESS";
export const UPDATE_VOLUME_LEVEL = "UPDATE_VOLUME_LEVEL";
export const SAVE_CHECKIN_STATUS = "SAVE_CHECKIN_STATUS";
export const DRIVER_FLOW_CHANGE = "DRIVER_FLOW_CHANGE";
export const PRE_TRIP_ANS_STATUS = "PRE_TRIP_ANS_STATUS";
export const RESET_DRIVER_DATA = "RESET_DRIVER_DATA";
export const RESET_HOME = "RESET_HOME";
export const RESTORE_CONFIG = "RESTORE_CONFIG";
export const ACTIVATION_CODE_ENTERED = "ACTIVATION_CODE_ENTERED";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const TOKEN_SENT = "TOKEN_SENT";
export const JOB_OFFER_ACCEPT = "JOB_OFFER_ACCEPT";
export const SET_EXPIRATION_TIME = "SET_EXPIRATION_TIME";
export const RESET_AUTHORIZATION = "RESET_AUTHORIZATION";
export const RESET_TRUCK_DATA = "RESET_TRUCK_DATA";
export const RESET_EQUIPEMENT_DATA = "RESET_EQUIPEMENT_DATA";
export const SAVE_ACTIVATION_CODE = "SAVE_ACTIVATION_CODE";
export const OTP_SENT = "OTP_SENT";
export const INDEMNITY_REFERRAL_FORM_COMPLETE =
  "INDEMNITY_REFERRAL_FORM_COMPLETE";
export const SET_DISPATCHER_JOB_NUMBER = "SET_DISPATCHER_JOB_NUMBER";
export const SET_DISPATCHER_DETAIL = "SET_DISPATCHER_DETAIL";
export const SELECT_DISPATCHER_TRUCK = "SELECT_DISPATCHER_TRUCK";
export const SAVE_DISPATCH_CHAT_NOTIFICATION =
  "SAVE_DISPATCH_CHAT_NOTIFICATION";
export const ON_PROVIDER_PROFILE_SUCCESS = "ON_PROVIDER_PROFILE_SUCCESS";
