import { POST } from "pr-utility/utils/network";
import {
  IS_LOADING,
  ADD_ONE_NOTIFICATION,
  LAST_ACTION_PERFOMED_JOB,
} from "../../actionTypes";
import { dispatcherJobAction } from "pr-utility/utils/APISignature/CheckInAPI";

export function isLoading(bool) {
  return {
    type: IS_LOADING,
    bool,
  };
}

export async function performDispatcherJobAction(
  vehicleUid,
  jobID,
  actionId,
  data
) {
  try {
    const response = await POST(
      dispatcherJobAction(vehicleUid, jobID, actionId),
      data
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function increaseNotificationCount() {
  return {
    type: ADD_ONE_NOTIFICATION,
  };
}

export function updateLastActionPerormedJob(response) {
  return {
    type: LAST_ACTION_PERFOMED_JOB,
    response,
  };
}
