import {
  CONNECTING,
  CONNECTED,
  DISCONNECTED,
  MESSAGERECEIVED,
} from "./actions";

import { ConnectionState } from "./constant";

export const initialState = {
  state: ConnectionState.Idle, // connection state
  error: null, // failure exception
};

const connectionReducer = (state = initialState, action) => {
  // console.log("Pubnub: connectionReducer");
  // console.log(action);
  switch (action.type) {
    case CONNECTING:
      return {
        error: "",
        state: ConnectionState.Connecting,
      };

    case CONNECTED:
      return {
        state: ConnectionState.Connected,
        error: "",
      };
    case DISCONNECTED:
      if (action.payload.error) {
        return {
          state: ConnectionState.Failed,
          error: action.payload.error.stack,
        };
      } else {
        return {
          ...state,
          state: ConnectionState.Idle,
        };
      }
    case MESSAGERECEIVED:
      if (action.payload.message) {
        return {
          ...state,
          state: ConnectionState.Failed,
          message: action.payload.message,
        };
      } else {
        return {
          ...state,
          state: ConnectionState.Idle,
        };
      }

    default:
      return state;
  }
};

export default connectionReducer;
