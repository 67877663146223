import * as types from "../../actionTypes";
import {
  providerManagement,
  activateCode,
  mobileNumberOTP,
  verifyOTP,
  getAllTechnicians,
  verifyTechnicianPassword,
  initiateOTPRequest,
  validateOTP,
} from "pr-utility/utils/APISignature/ProviderVerificationAPI";
import {
  truckCheckIn,
  getEntityDetail,
  getDriverConfig,
  uploadPhoto,
} from "pr-utility/utils/APISignature/CheckInAPI";
import * as network from "pr-utility/utils/network";
import client from "pr-utility/config/client";

export async function providerVerify(phoneNumber) {
  try {
    const response = await network.GET(providerManagement(phoneNumber));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function uploadDriverPhoto(Uid, data) {
  try {
    const response = await network.multipartPost(uploadPhoto(Uid), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function getEntityDetailAction(uid) {
  try {
    const response = await network.GET(getEntityDetail(uid));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function truckCheckInAction(data) {
  try {
    const response = await network.POST(truckCheckIn(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function verifyPassword(entityUid, data) {
  try {
    const response = await network.POST(
      verifyTechnicianPassword(entityUid),
      data
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function DriverDetailSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.DRIVER_DETAIL_SUCCESS,
      response,
    });
  };
}

export function SaveCheckinStatus(response) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_CHECKIN_STATUS,
      response,
    });
  };
}

export function providerVerificationSuccess(response, phoneNumber) {
  return (dispatch) => {
    dispatch({
      type: types.PROVIDER_VERIFICATION_SUCCESS,
      response,
    });
    dispatch({
      type: types.PROVIDER_PHONE_NUMBER,
      phoneNumber,
    });
  };
}

export function updateLocationSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_LOCATION_SUCCESS,
      response,
    });
  };
}

export async function activateApp(data) {
  try {
    const response = await network.POST(activateCode(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function loadConfig(params) {
  try {
    let configURL = client.getConfigURL();
    const response = await network.GET(configURL + params);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function loadConfigKeys(params) {
  try {
    let configURL = client.getConfigURL() + "/keys";
    const response = await network.GET(configURL + params);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function sendOTP(entityId, mobileNumber) {
  try {
    const response = await network.GET(mobileNumberOTP(entityId, mobileNumber));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function getDriverConfigAction(uid) {
  try {
    const response = await network.GET(getDriverConfig(uid));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function driverConfigSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_DRIVER_CONFIG_SUCCESS,
      response,
    });
  };
}

export async function verifyOTPCode(mobileNumber, otp) {
  try {
    const response = await network.GET(verifyOTP(mobileNumber, otp));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function fetchSuccessConfig(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_GET_CONFIG_SUCESS,
      response,
    });
  };
}

export function truckCheckInSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_TRUCK_CHECKIN_SUCCESS,
      response,
    });
  };
}

export function fetchSuccessConfigKeys(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_GET_CONFIG_KEYS_SUCCESS,
      response,
    });
  };
}

export function isLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      bool,
    });
  };
}

export function driverNumber(response) {
  return (dispatch) => {
    dispatch({
      type: types.DRIVER_PHONE_NUMBER,
      response,
    });
  };
}

export async function getAllTechniciansList(uid) {
  try {
    const response = await network.GET(getAllTechnicians(uid));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}
export async function initiateOTP(data) {
  try {
    const response = await network.POST(initiateOTPRequest(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function initiateOTPVerification(data) {
  try {
    const response = await network.POST(validateOTP(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function isOTPSent(boolean) {
  return (dispatch) => {
    dispatch({
      type: types.OTP_SENT,
      boolean,
    });
  };
}
export function indemnityReferralComplete(response) {
  return (dispatch) => {
    dispatch({
      type: types.INDEMNITY_REFERRAL_FORM_COMPLETE,
      response,
    });
  };
}
