import { GAEvents, sendGAEvent } from "pr-utility/utils/analytics";
import * as types from "../../actionTypes";
import * as network from "pr-utility/utils/network";
import {
  jobDetailDriver,
  jobDetailTruck,
} from "pr-utility/utils/APISignature/SettingsAPI";
import {
  driverAcion,
  truckAcion,
  dispatcherJobAction,
  dispatcherJobRejectAction,
} from "pr-utility/utils/APISignature/CheckInAPI";

export async function getJobDetails(entityId, jobID, entityType) {
  try {
    const response = await network.GET(
      entityType === "DRIVER"
        ? jobDetailDriver(entityId, jobID)
        : jobDetailTruck(entityId, jobID)
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function acceptJobOffer(
  entityType,
  driverId,
  jobID,
  actionId,
  data
) {
  try {
    const response = await network.POST(
      entityType === "DRIVER"
        ? driverAcion(driverId, jobID, actionId)
        : truckAcion(driverId, jobID, actionId),
      data
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function declinedJobList(
  technicianUid,
  jobNumber,
  actionId,
  data
) {
  try {
    const response = await network.POST(
      driverAcion(technicianUid, jobNumber, actionId),
      data
    );
    return response;
  } catch (err) {}
}

export function jobDetailSuccess(response, jobID) {
  return (dispatch) => {
    dispatch({
      type: types.JOB_ID,
      jobID,
    });
  };
}

export function isLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      bool,
    });
  };
}

export function jobOfferAccept(bool) {
  return (dispatch) => {
    dispatch({
      type: types.JOB_OFFER_ACCEPT,
      bool,
    });
  };
}

export function updateLastActionPerormedJob(response) {
  return (dispatch) => {
    dispatch({
      type: types.LAST_ACTION_PERFOMED_JOB,
      response,
    });
  };
}

export function increaseNotificationCount() {
  return (dispatch) => {
    dispatch({
      type: types.ADD_ONE_NOTIFICATION,
    });
  };
}

export async function dispatcherAcceptJobOffer(
  vehicleUid,
  jobID,
  actionId,
  data
) {
  try {
    sendGAEvent(GAEvents.JOB_OFFER_ACCEPTED);
    const response = await network.POST(
      dispatcherJobAction(vehicleUid, jobID, actionId),
      data
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function dispatcherRejectJobOffer(jobID, actionId, data) {
  try {
    sendGAEvent(GAEvents.JOB_OFFER_DECLINED);
    const response = await network.POST(
      dispatcherJobRejectAction(jobID, actionId),
      data
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}
