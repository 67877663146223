import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  selectedTruck: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SELECT_TRUCK: {
      return {
        ...state,
        selectedTruck: action.response,
      };
    }
    case types.RESET_TRUCK_DATA: {
      return {
        ...initialState,
      };
    }
    case types.SELECT_DISPATCHER_TRUCK: {
      return {
        ...state,
        selectedTruck: action.response,
      };
    }

    default:
      return state;
  }
}
