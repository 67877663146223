import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Header from "../CommonComponent/Header";
import { FormattedMessage } from "react-intl";
import Cleave from "cleave.js/dist/cleave-react";
import * as actions from "./Actions";
import AlertMessage from "../CommonComponent/AlertMessage";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { onBlurEvent, scroll } from "pr-utility/utils/utils";
import notification from "pr-utility/utils/notification";
import { postType } from "pr-utility/utils/postMessage";
//import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
class MobileVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PIN: "",
      activateEnabled: false,
      PINEntered: false,
      showAlert: false,
      alertData: {},
      PINLength: 6,
      driverInfo: {},
      driverImageState: null,
      recievedData: {},
    };

    this.showAlertModal = this.showAlertModal.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  onPinEntered = (event) => {
    if (this.state.PIN === event.target.rawValue) {
      return;
    }

    this.setState({ activateEnabled: false });

    if (
      event.target.value !== "" &&
      event.target.value.length !== 0 &&
      event.target.value.length === this.state.PINLength &&
      !this.state.PINEntered &&
      this.state.PIN !== event.target.rawValue
    ) {
      this.setState({
        PINEntered: true,
        PIN: event.target.rawValue,
      });

      document.getElementById("cardNum").blur();
    } else {
      this.setState({
        PINEntered: false,
        activateEnabled: false,
        PIN: event.target.rawValue,
      });
    }
  };

  showAlertModal(netWorkError, response, callback, params) {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.company_not_activated" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    }
    this.setState({ showAlert: true, alertData });
  }

  handleBack() {
    if (process.env.REACT_APP_COUNTRY === "US") {
      let userData = {
        type: postType.LOGOUT,
        dto: {
          data: {},
          error: false,
          errorMessage: "",
        },
      };
      notification.postMessage(userData);
    } else {
      this.props.history.push("/activate-app");
    }
  }

  verifyOTP = () => {
    //TO-DO check in possible from home only
    this.validatePin(this.state.PIN, () => {
      this.props.history.push("/user");
    });
  };

  componentDidMount() {
    const { AppConfig, DriverData, Authorization: authorization } = this.props;

    if (DriverData && DriverData.otpSent === null) {
      this.resendOTP();
    }

    let page = "mobile-verification";
    let data = {
      AppConfig,
      authorization,
      DriverData,
    };

    let type = postType.APP_STATE;
    notification.sendMessageToNative(page, data, type);

    let newType = postType.LOAD_WEB_VIEW;
    notification.sendMessageToNative("", {}, newType);
  }

  handleResend = () => {
    this.resendOTP();
  };

  validatePin = async (PIN, callback) => {
    this.props.dispatch(actions.isLoading(true));
    const { entityType, entityUid } = this.props;

    const data = {
      entityId: entityUid,
      entityType,
      code: this.state.PIN,
    };

    const response = await actions.initiateOTPVerification(data);

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      callback();
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.validatePin, [PIN, callback]);
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
    }
  };

  resendOTP = async () => {
    this.props.dispatch(actions.isLoading(true));
    const { entityType, entityUid } = this.props;

    const data = {
      entityId: entityUid,
      entityType,
    };

    const response = await actions.initiateOTP(data);

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      this.props.dispatch(actions.isOTPSent(true));
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.resendOTP);
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
    }
  };

  render() {
    let continue_button = <FormattedMessage id="user.submit_button" />;

    let label = <FormattedMessage id="activate_app.verification_code_label" />;
    let wrapper_class, page_header, page_title, page_description;
    if (process.env.REACT_APP_COUNTRY === "US") {
      wrapper_class = "parent_us";
      page_header = <FormattedMessage id="activate_app.navbar_header" />;
      page_title = <FormattedMessage id="activate_app.verify_user_profile" />;
      page_description = (
        <FormattedMessage id="activate_app.verify_user_description" />
      );
    }
    /*eslint-disable no-script-url*/
    return (
      <div className={`pg bgGray ${wrapper_class}`}>
        <Header title={page_header} left="true" backButton={this.handleBack} />
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
        <div className="content resetContent">
          <div className="">
            <div className="clearfix" />
            <div className="gap10" />
            <div className="text-center">
              <h2 className="pgTitle page_title">{page_title}</h2>
              <div className="activate_message">{page_description}</div>
            </div>
            <div className="gap10" />
            <h2 className="listViewTitle fontSize18">{label}</h2>
            <div className="listViewBox">
              <ul className="type1">
                <li>
                  <span className="text">
                    <Cleave
                      value={this.state.PIN}
                      className="txtBox"
                      id="cardNum"
                      placeholder="Code"
                      options={{
                        blocks: [6],
                        delimiter: "",
                        numericOnly: true,
                      }}
                      type="tel"
                      autoComplete="off"
                      maxLength={this.state.PINLength}
                      onChange={this.onPinEntered}
                      onFocus={() => scroll(".content")}
                      onClick={() => scroll(".content")}
                      onBlur={onBlurEvent}
                      required
                    />
                  </span>
                  <span className="arrow d-none">
                    <i className="icon ion-ios-arrow-right" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="text-center">
            <span className="lnk mb25" onClick={this.handleResend}>
              <FormattedMessage id="activate_app.resend_code" />
            </span>
          </div>
        </div>
        <footer className="bottom_fix bgGray">
          <div className="container clearfix pl-0 pr-0 text-center">
            <span
              className={"btn mb0 " + (this.state.PINEntered ? "" : "disabled")}
              onClick={this.verifyOTP}
            >
              {continue_button}
            </span>
          </div>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entityUid, entityType } = state.Authorization.authorization;

  return {
    entityUid,
    entityType,
    AppConfig: state.AppConfig,
    DriverData: state.DriverData,
    Authorization: state.Authorization.authorization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileVerification)
);
