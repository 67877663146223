import * as types from "../../actionTypes";
import {
  activateCode,
  appEvent,
  dispatcherAppEvent,
} from "pr-utility/utils/APISignature/ProviderVerificationAPI";
import {
  truckCheckIn,
  getVehicleDetail,
  getVehicleConfig,
} from "pr-utility/utils/APISignature/CheckInAPI";
import * as network from "pr-utility/utils/network";
import client from "pr-utility/config/client";

export function authDataSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_AUTH_DATA_SUCCESS,
      response,
    });
  };
}

export function restoreAuthDataSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_RESTORE_AUTH_DATA_SUCCESS,
      response,
    });
  };
}

export function saveInitAppData(response) {
  return (dispatch) => {
    dispatch({
      type: types.INIT_APP_DATA,
      response,
    });
  };
}

export function tokenSent(response) {
  return (dispatch) => {
    dispatch({
      type: types.TOKEN_SENT,
      response,
    });
  };
}

export function updateLocationSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_LOCATION_SUCCESS,
      response,
    });
  };
}

export function saveVolumeLevel(response) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_VOLUME_LEVEL,
      response,
    });
  };
}

//dispatch chat
export function saveDispatchChatNotificationCount(response) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_DISPATCH_CHAT_NOTIFICATION,
      response,
    });
  };
}

export function truckDetailSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.VEHICLE_DETAIL_SUCCESS,
      response,
    });
  };
}

export function DriverDetailSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.DRIVER_DETAIL_SUCCESS,
      response,
    });
  };
}

export function SaveCheckinStatus(response) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_CHECKIN_STATUS,
      response,
    });
  };
}

export function truckConfigSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_TRUCK_CONFIG_SUCCESS,
      response,
    });
  };
}
export function driverConfigSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_DRIVER_CONFIG_SUCCESS,
      response,
    });
  };
}

export function driverFlowToggle(response) {
  return (dispatch) => {
    dispatch({
      type: types.DRIVER_FLOW_CHANGE,
      response,
    });
  };
}

export function selectTruck(response) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_TRUCK,
      response,
    });
  };
}

export function selectEquipment(response) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_EQUIPEMENT,
      response,
    });
  };
}

export function restoreConfig(response) {
  return (dispatch) => {
    dispatch({
      type: types.RESTORE_CONFIG,
      response,
    });
  };
}

export function isLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      bool,
    });
  };
}

export async function loadConfig(params) {
  try {
    if (client.CONFIG_URL === "") {
      client.setConfigURL("https://dev03-configs.allianzau.urgent.ly/configs");
    }
    let configURL =
      client.CONFIG_URL +
      `?v=${client.API_VERSION}&env=${client.ENVIROMENTUL}&industry=${client.INDUSTRY_TYPE}&device=${client.DEVICE}&os=${client.DEVICE_OS_VERSION}&app=${client.APP}`;
    const response = await network.GETAuth(configURL);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function fetchSuccessConfig(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_GET_CONFIG_SUCESS,
      response,
    });
  };
}

export function fetchSuccessConfigKeys(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_GET_CONFIG_KEYS_SUCCESS,
      response,
    });
  };
}

export async function activateApp(data) {
  try {
    const response = await network.POST_NO_TOKEN(activateCode(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function activateAppSuccess(data) {
  try {
    let endPoint;
    if (process.env.REACT_APP_COUNTRY === "AU") {
      endPoint = appEvent();
    } else if (process.env.REACT_APP_COUNTRY === "US") {
      endPoint = dispatcherAppEvent();
    }
    const response = await network.POST(endPoint, data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function getTruckConfigAction(uid) {
  try {
    const response = await network.GET(getVehicleConfig(uid));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function getTruckDetailAction(uid) {
  try {
    const response = await network.GET(getVehicleDetail(uid));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function loadConfigKeys(params) {
  try {
    let configURL =
      client.CONFIG_URL +
      `/keys?v=${client.API_VERSION}&env=${client.ENVIROMENTUL}&industry=${client.INDUSTRY_TYPE}&device=${client.DEVICE}&os=${client.DEVICE_OS_VERSION}&app=${client.APP}`;
    const response = await network.GETAuth(configURL);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function truckCheckInAction(data) {
  try {
    const response = await network.POST(truckCheckIn(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function saveActivationCode(activationCode) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_ACTIVATION_CODE,
      activationCode,
    });
  };
}

export function indemnityReferralSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.INDEMNITY_REFERRAL_FORM_COMPLETE,
      response,
    });
  };
}

export function providerProfileSuccess(response) {
  return {
    type: types.ON_PROVIDER_PROFILE_SUCCESS,
    response,
  };
}
