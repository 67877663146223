import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoginShell from "pr-login/src/asyncShell";
import Loader from "./Loader";
import { IsDispatcherLocalstorageExist } from "pr-utility/utils/utils";

const HomeShell = React.lazy(() => import("pr-home/src/asyncShell"));

const SettingsShell = React.lazy(() => import("pr-settings/src/asyncShell"));

class App extends Component {
  render() {
    const { AppConfig, entityType } = this.props;

    let loaded = AppConfig.is_loading;
    return (
      <React.Fragment>
        {IsDispatcherLocalstorageExist() ? (
          <Loader loaded={AppConfig ? !loaded : false} />
        ) : (
          <Loader loaded={!loaded} />
        )}
        <LoginShell />
        {IsDispatcherLocalstorageExist() ? (
          <Suspense fallback={<Loader loaded={AppConfig.is_loading} />}>
            <HomeShell />
          </Suspense>
        ) : (
          ""
        )}
        {IsDispatcherLocalstorageExist() ? (
          <Suspense fallback={<div> </div>}>
            <SettingsShell />
          </Suspense>
        ) : (
          ""
        )}
        {!IsDispatcherLocalstorageExist() && entityType && (
          <Suspense fallback={<Loader loaded={loaded} />}>
            <HomeShell />
          </Suspense>
        )}
        {!IsDispatcherLocalstorageExist() && entityType && (
          <Suspense fallback={<div> </div>}>
            <SettingsShell />
          </Suspense>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { entityType } = state.Authorization.authorization;

  return {
    entityType,
    AppConfig: state.AppConfig,
  };
}

export default withRouter(connect(mapStateToProps)(App));
