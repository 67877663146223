import React from "react";
import dayjs from "dayjs";
import { store } from "../../store";
import { FormattedMessage } from "react-intl";
import { serviceType, JobState } from "./constant";
import notification from "./notification";
import { postType } from "./postMessage";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import {
  showAlert,
  setAlertData,
  isLoading,
  triggerJobTagsAPI,
} from "pr-utility/common/Actions";
import {
  performDispatcherJobAction,
  updateLastActionPerormedJob,
} from "pr-home/src/screens/EndService/Actions";
import { LogAllEvent, EventId } from "pr-utility/app-events/app-event-logger";
import history from "pr-utility/history/history";

export const countryList = {
  AU: "61",
  UK: "44",
  US: "1",
  IND: "91",
};

export const countryMobileLength = {
  AU: 12,
  UK: 12,
  US: 14,
  IND: 12,
};

export const mobileNumberFormat = {
  AU: {
    blocks: [4, 3, 3],
    delimiters: [" ", " "],
    numericOnly: true,
  },
  UK: {
    blocks: [4, 3, 3],
    delimiters: [" ", " "],
    numericOnly: true,
  },
  US: {
    blocks: [0, 3, 0, 3, 4],
    delimiters: ["(", ")", " ", "-"],
    numericOnly: true,
  },
  IND: {
    blocks: [4, 3, 3],
    delimiters: [" ", " "],
    numericOnly: true,
  },
};

export function soundPlayback(startStopBool) {
  let Data = {
    type: postType.SOUND_PLAYBACK,
    dto: {
      data: {
        soundPlayback: startStopBool ? "start" : "stop",
      },
      error: false,
      errorMessage: "",
    },
  };
  notification.postMessage(Data);
}

export function formatPhoneNumber(tel, countryCode) {
  var numbers,
    chars,
    phoneNumber = "",
    i;
  if (countryCode === 1 || !countryCode) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 0: "(", 3: ") ", 6: "-" };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode === 61) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 4: " ", 7: " " };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode === 44) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 4: " ", 7: " " };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else if (countryCode === 91) {
    if (tel) {
      numbers = tel.replace(/\D/g, "");
      chars = { 4: " ", 7: " " };
      for (i = 0; i < numbers.length; i++) {
        phoneNumber += (chars[i] || "") + numbers[i];
      }
      return phoneNumber;
    }
  } else {
    return tel;
  }
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function isValidNumber(event) {
  var theEvent = event || window.event;
  var key;

  // Handle paste
  if (theEvent.type === "paste") {
    key = event.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }
  var regex = /[0-9]|\./;
  if (!regex.test(key)) {
    return false;
  } else {
    return true;
  }
}

export function onlyNumberAllowed(event) {
  if (event.shiftKey || event.ctrlKey || event.altKey) {
    event.preventDefault();
    return false;
  } else {
    const charCode = event.which || event.keyCode;
    if (
      charCode !== 8 &&
      charCode !== 0 &&
      (charCode <= 47 || charCode >= 58) &&
      (charCode <= 95 || charCode >= 106)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

export function upperCaseNumberRestriction(event) {
  if (event.shiftKey || event.ctrlKey || event.altKey) {
    event.preventDefault();
    return false;
  } else {
    var charCode = event.keyCode || event.which;
    if (
      charCode !== 8 &&
      charCode !== 0 &&
      (charCode <= 47 || charCode >= 58) &&
      (charCode <= 64 || charCode >= 91)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}

export function getDistanceFromLatLon(lat1, lon1, lat2, lon2, unit = "m") {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  if (unit === "m") {
    d = d / 1.609344; // Distance in mile
  }
  d = d.toFixed(2);
  return d;
}

export function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function checkTokenExpiration(expirationTime) {
  return new Date().getTime() > expirationTime - 600 * 1000;
}

export function getNextPage(flow) {
  var currentPage = window.location.hash.replace("#/", "");
  var pages = [];
  for (var page in flow) {
    pages.push(flow[page]);
  }
  var index = pages.indexOf(currentPage);
  return pages[index + 1];
}

export function checkForStateChange(oldState, newState) {
  if (oldState.provider && newState.provider) {
    let statusSequesnceNonTow = [0, 1101, 1102],
      statusSequesnceTow = [0, 1101, 1102, 1210];
    if (oldState.provider.status === newState.provider.status) {
      return false;
    } else {
      let indexOfNewStatus, indexOfOldStatus;
      if (oldState.service.serviceType === serviceType.TOW) {
        indexOfNewStatus = statusSequesnceTow.indexOf(newState.provider.status);
        indexOfOldStatus = statusSequesnceTow.indexOf(oldState.provider.status);
      } else {
        indexOfNewStatus = statusSequesnceNonTow.indexOf(
          newState.provider.status
        );
        indexOfOldStatus = statusSequesnceNonTow.indexOf(
          oldState.provider.status
        );
      }

      return !(indexOfNewStatus <= indexOfOldStatus);
    }
  }
}

export function isActionEnabled(entity, truckAction) {
  return (
    entity === "DRIVER" ||
    (entity === "VEHICLE" && truckAction === "true") ||
    entity === "DISPATCHER"
  );
}

export function informNativeForJobStatusChange(
  status,
  personalConfig,
  distanceForOnTheWayNotMoving
) {
  let userData = {
    type: postType.DRIVER_NAVIGATION,
    dto: {
      data: {
        Status: `job_${status}`,
        LocationUpdateConfig: {
          onJobLocationUpdateInterval:
            personalConfig.onJobLocationUpdateInterval,
          idleLocationUpdateInterval: personalConfig.idleLocationUpdateInterval,
          jobOfferDuration: personalConfig.jobOfferDuration,
          notMovingLocationUpdateInterval:
            personalConfig.notMovingLocationUpdateInterval,
          distanceForOnTheWayMoving: distanceForOnTheWayNotMoving || 0.2,
        },
      },
      error: false,
      errorMessage: "",
    },
  };
  notification.postMessage(userData);
}

export function deepMergeCustom(dataJson, baseJson) {
  for (var obj in dataJson) {
    if (dataJson[obj] instanceof Object) {
      if (baseJson[obj] === undefined) {
        baseJson[obj] = dataJson[obj];
      }
      deepMergeCustom(dataJson[obj], baseJson[obj]);
    } else {
      baseJson[obj] = dataJson[obj];
    }
  }
}

export function onBlurEvent() {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 10);
}

export function reInitDispatcherApp() {
  let dispatchData = window.localStorage.getItem("dispatcherData");
  if (dispatchData) {
    history.push("/dispatch");
  }
}

export function IsDispatcherLocalstorageExist() {
  let dispatchData = window.localStorage.getItem("dispatcherData");

  return dispatchData;
}

export function getDispatcherLocalstorage() {
  let dispatchData = window.localStorage.getItem("dispatcherData");
  if (dispatchData) {
    return JSON.parse(dispatchData);
  }
}

export function removeDispatcherLocalstorage() {
  window.localStorage.removeItem("dispatcherData");
}

export function setDispatcherLocalstorage(data) {
  window.localStorage.setItem("dispatcherData", JSON.stringify(data));
}

export function getReduxStore() {
  return store.getState();
}

export function scroll(elementClass, duration = 100, effect = "slow") {
  const $ = window.$;
  setTimeout(function () {
    $(document).ready(function () {
      $(elementClass).animate(
        { scrollTop: $(document).height() + 100 },
        effect
      );
      return false;
    });
  }, duration);
}

export function getJobCost(job) {
  return job.provider.costs.find((cost) => cost.type === "BASE");
}

export function isUS() {
  return process.env.REACT_APP_COUNTRY === "US";
}

export function isAUS() {
  return process.env.REACT_APP_COUNTRY === "AU";
}

export function getCurrencySymbol() {
  const { DriverData } = store.getState();
  const { currency } = DriverData?.networkConfig;
  let currencySymbol = "";
  switch (currency) {
    case "INR":
      currencySymbol = <>&#x20B9;</>;
      break;
    case "GBP":
      currencySymbol = <>&#163;</>;
      break;
    case "EURO":
      currencySymbol = <>&#8364;</>;
      break;
    default:
      //AUS, CAD, US
      currencySymbol = <>&#36;</>;
      break;
  }
  return currencySymbol;
}

export function isBMW() {
  return process.env.REACT_APP_BRAND === "BMW";
}

export const printCompletedDate = function (dateTime) {
  return dayjs(dateTime).format("MM/DD/YY, h:mm A");
};

export const getFullFormattedDate = function (dateTime) {
  return dayjs(dateTime).format("ddd MMM DD, h:mm A");
};

export const getFormattedDate = function (dateTime) {
  return isUS()
    ? dayjs(dateTime).format("MM/DD/YY")
    : dayjs(dateTime).format("ddd MMM DD, h:mm A");
};

export const getFormattedTime = function (dateTime) {
  return dayjs(dateTime).format("h:mm A");
};

export function isJLR() {
  return process.env.REACT_APP_BRAND === "JLR";
}

export function isURGENTLY() {
  return (
    process.env.REACT_APP_BRAND === "URGENT.LY" ||
    process.env.REACT_APP_BRAND === "JLR"
  );
}

export function openExternalCommunication(linkToOpen) {
  document.location.href = linkToOpen;
}

export const showAlertModal = ({
  response,
  cbFunc = undefined,
  cbParams = [],
  additionalCbFunc = undefined,
}) => {
  // log errors
  sendErrorLog(response);

  let alertData;
  if (response && response?.code === "ECONNABORTED") {
    alertData = {
      header: <FormattedMessage id="common.timeout_header" />,
      body: <FormattedMessage id="common.timeout_message" />,
      leftText: <FormattedMessage id="common.retry" />,
      leftOnClick: () => {
        showHideAlert();

        if (typeof cbFunc === "function") {
          // it will send respective params whether we have or not
          cbFunc(...cbParams);
        }
      },
      rightText: <FormattedMessage id="common.cancel" />,
      rightOnClick: () => {
        showHideAlert();

        if (typeof additionalCbFunc === "function") {
          additionalCbFunc();
        }
      },
    };
  } else if (response === undefined || (response && response.status === 400)) {
    alertData = {
      header: "",
      body: <FormattedMessage id="profile.error_msg" />,
      rightText: <FormattedMessage id="profile.close" />,
      rightOnClick: () => {
        showHideAlert();

        if (typeof additionalCbFunc === "function") {
          additionalCbFunc();
        }
      },
    };
  } else {
    let header = response ? <FormattedMessage id="profile.oops" /> : "";
    let body = response?.data?.error || (
      <FormattedMessage id="profile.error_msg" />
    );

    alertData = {
      header,
      body,
      rightText: <FormattedMessage id="common.ok" />,
      rightOnClick: () => {
        showHideAlert();

        if (typeof additionalCbFunc === "function") {
          additionalCbFunc();
        }
      },
    };
  }

  showHideAlert(alertData, true);
};

export function showHideAlert(alertData = null, isShowAlert = false) {
  if (alertData) {
    store.dispatch(setAlertData(alertData));
  }

  store.dispatch(showAlert(isShowAlert));
}

export const isValidResponse = (response) =>
  response && Number(response.status) === 200;

export const getTagValue = (tags = [], tagName) =>
  tags.find((tagItem) => tagItem.tag === tagName)?.tagValue || "";

export async function fetchJobTags(tagName, jobNumber) {
  store.dispatch(isLoading(true));

  const response = await triggerJobTagsAPI(jobNumber);

  store.dispatch(isLoading(false));

  if (isValidResponse(response)) {
    const tagList = response.data.data;

    return getTagValue(tagList, tagName);
  } else {
    const obj = {
      response,
      cbFunc: fetchJobTags,
      cbParams: [tagName, jobNumber],
    };

    showAlertModal(obj);
  }
}

export async function triggerJobComplete() {
  const { Home, Truck } = store.getState();
  const { activeJobList, lastActionPerformedJob } = Home;
  const { selectedTruck } = Truck;

  store.dispatch(isLoading(true));

  const jobID = activeJobList?.service?.number ?? "";

  let response = await performDispatcherJobAction(
    selectedTruck.uid,
    jobID,
    1204,
    {}
  );

  store.dispatch(isLoading(false));

  if (isValidResponse(response)) {
    const logParams = {
      eventId: EventId.JOB_COMPLETED,
      jobID,
    };

    LogAllEvent(logParams);

    let lastActionjobs = [
      ...lastActionPerformedJob,
      {
        job: activeJobList,
        LastActionPerformTime: new Date().getTime(),
      },
    ];
    await store.dispatch(updateLastActionPerormedJob(lastActionjobs));

    history.push("/thanks");
  } else {
    const obj = {
      response,
      cbFunc: triggerJobComplete,
    };

    showAlertModal(obj);
  }
}

export function isJobTowExchange(jobServiceId) {
  return jobServiceId === JobState.JOB_STATUS_TOW_EXCHANGE;
}
