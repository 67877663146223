/**
 * Created by pratik.panchal on 06-09-2018.
 */
import ApiConfigs from "../../config/client";

export const jobCancelReason = (driverId) => {
  let configUrl = ApiConfigs.getConfigURL();
  return `${configUrl}/personnel/cancel/reasons?id=${driverId}`;
};

export const entitlementData = (customerId) => {
  return `${ApiConfigs.HOST_URL}/ops/policy/customer/${customerId}`;
};

export const getAllParts = (Uid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${Uid}/parts-inventory`;
};

export const getAllPartsVehicle = (Uid) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/${Uid}/parts-inventory`;
};

export const addParts = (jobId, Uid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${Uid}/job/${jobId}/parts`;
};

export const addPartsVehicle = (jobId, Uid) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/${Uid}/job/${jobId}/parts`;
};

export const getJobMetrics = (jobId) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/jobs/${jobId}/job-metric`;
};

export const getJobMetricsVehicle = (jobId) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/jobs/${jobId}/job-metric`;
};

export const sendJobanswers = (jobId) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/jobs/${jobId}/job-metric-response`;
};

export const sendJobanswersVehicle = (jobId) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/jobs/${jobId}/job-metric-response`;
};

export const getAfterHoursOperation = (facilityId) => {
  return `${ApiConfigs.HOST_URL}/facilities/tags/find-by-facility-id-and-tag?facilityId=${facilityId}`;
};
export function jobTagsApi(jobNumber) {
  return `${ApiConfigs.HOST_URL}/tags/${jobNumber}`;
}
//TO-DO make urgently dynamic
