import * as types from "../../actionTypes";

export function authDataSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_AUTH_DATA_SUCCESS,
      response,
    });
  };
}

export function saveInitAppData(response) {
  return (dispatch) => {
    dispatch({
      type: types.INIT_APP_DATA,
      response,
    });
  };
}

export function tokenSent(response) {
  return (dispatch) => {
    dispatch({
      type: types.TOKEN_SENT,
      response,
    });
  };
}

export function updateLocationSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_LOCATION_SUCCESS,
      response,
    });
  };
}

export function saveVolumeLevel(response) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_VOLUME_LEVEL,
      response,
    });
  };
}

//dispatch chat
export function saveDispatchChatNotificationCount(response) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_DISPATCH_CHAT_NOTIFICATION,
      response,
    });
  };
}

export function truckDetailSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.VEHICLE_DETAIL_SUCCESS,
      response,
    });
  };
}

export function DriverDetailSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.DRIVER_DETAIL_SUCCESS,
      response,
    });
  };
}

export function SaveCheckinStatus(response) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_CHECKIN_STATUS,
      response,
    });
  };
}

export function truckConfigSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_TRUCK_CONFIG_SUCCESS,
      response,
    });
  };
}
export function driverConfigSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_DRIVER_CONFIG_SUCCESS,
      response,
    });
  };
}

export function driverFlowToggle(response) {
  return (dispatch) => {
    dispatch({
      type: types.DRIVER_FLOW_CHANGE,
      response,
    });
  };
}

export function selectTruck(response) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_TRUCK,
      response,
    });
  };
}

export function selectEquipment(response) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_EQUIPEMENT,
      response,
    });
  };
}

export function restoreConfig(response) {
  return (dispatch) => {
    dispatch({
      type: types.RESTORE_CONFIG,
      response,
    });
  };
}
