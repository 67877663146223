import ApiConfigs from "../../config/client";

export const providerDriver = (mobileNumber) => {
  return `${ApiConfigs.HOST_URL}/provider-drivers?providerId=${mobileNumber}`;
};
export const checkInDriverURL = (providerId, driverId) => {
  return `${ApiConfigs.HOST_URL}/personnel/urgently:${providerId}:${driverId}/checkin`;
};
export const checkOutDriverURL = (providerId, driverId) => {
  return `${ApiConfigs.HOST_URL}/personnel/urgently:${providerId}:${driverId}/checkout`;
};
export const driverAcion = (technicianUid, jobNumber, actionId) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${technicianUid}/jobs/${jobNumber}/action/${actionId}`;
};

export const truckAcion = (technicianUid, jobNumber, actionId) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/${technicianUid}/jobs/${jobNumber}/action/${actionId}`;
};

// driver check in api

export const technicianCheckIn = () => {
  return `${ApiConfigs.HOST_URL}/provider-driver/checkin`;
};

export const technicianCheckOut = () => {
  return `${ApiConfigs.HOST_URL}/provider-driver/checkout`;
};

export const technicianDeviceToken = (entityUid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${entityUid}`;
};

export const technicianLocationUpdate = (entityUid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${entityUid}/location`;
};

export const technicianLocationUpdateJob = (entityUid, jobNumber) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${entityUid}/location?jobNumber=${jobNumber}`;
};

export const vehicleDriverAssignment = (vehicleUid, technicianUid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/assign-to-vehicle?vehicleUid=${vehicleUid}&technicianUid=${technicianUid}`;
};

export const vehicleEquipmentAssignment = (equipmentUid) => {
  return `${ApiConfigs.HOST_URL}/vehicle-equipments/${equipmentUid}`;
};

export const vehicleList = (driverUid, limit, offset) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/vehicles?id=${driverUid}&limit=${limit}&offset=${offset}`;
};

export const equipmentList = (driverUid, limit, offset) => {
  return `${ApiConfigs.HOST_URL}/vehicle-equipments/get-equipments?providerId=${driverUid}&limit=${limit}&offset=${offset}`;
};

export const vehicleDeviceToken = () => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/update-vehicle`;
};

export const vehicleLocationUpdate = (entityUid) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/${entityUid}/location`;
};

export const vehicleLocationUpdateJob = (entityUid, jobNumber) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/${entityUid}/location?jobNumber=${jobNumber}`;
};

//truck check in

export const truckCheckIn = () => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/checkin`;
};

export const truckCheckOut = () => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/checkout`;
};

export const getEntityDetail = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver?id=${uid}`;
};

export const getVehicleDetail = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/get-vehicle?id=${uid}`;
};

export const getVehicleConfig = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/config/${uid}`;
};

export const getDriverConfig = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/config/${uid}`;
};

export const uploadPhoto = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${uid}/photo`;
};

export const updateDriverReachable = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${uid}/reachable`;
};

export const declineReason = () => {
  return `${ApiConfigs.HOST_URL}/provider-app-config/decline-reasons`;
};

export const getIndemnity = (jobId, formType) => {
  return `${ApiConfigs.HOST_URL}/questionnaire/questions/jobId/${jobId}?form=${formType}`;
};

export const postIndemnity = (jobId) => {
  return `${ApiConfigs.HOST_URL}/questionnaire/answers/jobId/${jobId}`;
};

export const postSign = () => {
  return `${ApiConfigs.HOST_URL}/provider-driver/job-inspection-signature`;
};

export const skipUserSign = () => {
  return `${ApiConfigs.HOST_URL}/inspections/customers/signature/skip`;
};

export const logoutTechnician = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-driver/${uid}/logout`;
};

export const logoutTruck = (uid) => {
  return `${ApiConfigs.HOST_URL}/provider-vehicles/${uid}/logout`;
};

export const getDispatcherDetail = () => {
  return `${ApiConfigs.HOST_URL}/providers/profile`;
};

export const dispatcherVehicleList = (limit, offset) => {
  return `${ApiConfigs.HOST_URL}/providers/vehicles?limit=${limit}&offset=${offset}`;
};

export const dispatcherJobData = (jobNumber) => {
  return `${ApiConfigs.HOST_URL}/providers/jobs/${jobNumber}`;
};

export const dispatcherJobAction = (vehicleUid, jobNumber, actionId) => {
  return `${ApiConfigs.HOST_URL}/providers/actions/${actionId}?vehicleUid=${vehicleUid}&jobNumber=${jobNumber}`;
};
export const dispatcherJobRejectAction = (jobNumber, actionId) => {
  return `${ApiConfigs.HOST_URL}/providers/actions/${actionId}?jobNumber=${jobNumber}`;
};
export const dispatcherJobCancelReason = (providerId) => {
  return `${ApiConfigs.HOST_URL}/provider-app-config/cancel-reasons?id=${providerId}`;
};
