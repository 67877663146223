import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  indemnity: null,
  customerApprovalAns: [],
  customerSignature: "",
  dealerSignature: "",
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.INDEMNITY_SUCCESS: {
      return {
        ...state,
        indemnity: action.response,
      };
    }
    case types.SAVE_CUSTOMER_APPROVAL: {
      return {
        ...state,
        customerApprovalAns: action.response,
      };
    }
    case types.SAVE_CUSTOMER_SIGNATURE: {
      return {
        ...state,
        customerSignature: action.response,
      };
    }
    case types.SAVE_DEALER_SIGNATURE: {
      return {
        ...state,
        dealerSignature: action.response,
      };
    }
    case types.IS_LOADING: {
      return {
        ...state,
        is_loading: action.bool,
      };
    }
    case types.CLEAR_INDEMNITY_STORE: {
      return {
        indemnity: null,
        customerApprovalAns: [],
        customerSignature: "",
        dealerSignature: "",
      };
    }
    default:
      return state;
  }
}
