import * as types from "./actionTypes";
import * as network from "pr-utility/utils/network";
import client from "pr-utility/config/client";
import {
  getAfterHoursOperation,
  jobTagsApi,
} from "pr-utility/utils/APISignature/Home";

export async function refreshToken(auth) {
  try {
    let params = {
      accessToken: auth.accessToken,
      refreshToken: auth.refreshToken,
      scope: auth.scope,
      entityType: auth.entityType,
      entityUid: auth.entityUid,
    };
    let rqURL = client.getRefreshURL();
    const response = await network.POST_NO_TOKEN(rqURL, params);
    return response.data;
  } catch (err) {}
}

export function refreshTokenSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.REFRESH_TOKEN,
      response,
    });
  };
}

export function setExpirationTime(response) {
  return (dispatch) => {
    dispatch({
      type: types.SET_EXPIRATION_TIME,
      response,
    });
  };
}

export function isLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      bool,
    });
  };
}

export function tokenSent(response) {
  return (dispatch) => {
    dispatch({
      type: types.TOKEN_SENT,
      response,
    });
  };
}

export async function getAfterHoursOperationData(facilityId) {
  try {
    const response = await network.GET(getAfterHoursOperation(facilityId));
    return response;
  } catch (err) {
    if (!err.response) return undefined;
    else {
      return err.response;
    }
  }
}

export function showAlert(response) {
  return {
    type: types.SHOW_ALERT,
    response,
  };
}

export function setAlertData(response) {
  return {
    type: types.ALERT_DATA,
    response,
  };
}

export async function triggerJobTagsAPI(jobNumber) {
  try {
    const response = await network.GET(jobTagsApi(jobNumber));

    return response;
  } catch (error) {
    return error.response || error;
  }
}
