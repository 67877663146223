import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFeatureFlags } from "pr-utility/common/reduxActions";

const LaunchDarkly = () => {
  const flags = useFlags();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFeatureFlags(flags));
    // eslint-disable-next-line
  }, [flags]);

  return null;
};

export default LaunchDarkly;
