import React, { Component } from "react";
import { SAD_FACE } from "pr-utility/images/index";
import notification from "pr-utility/utils/notification";
import { postType } from "pr-utility/utils/postMessage";
import {
  IsDispatcherLocalstorageExist,
  getReduxStore,
} from "pr-utility/utils/utils";

class ReportFeedback extends Component {
  closeApp = () => {
    let authorization = getReduxStore().Authorization.authorization;
    if (
      Object.keys(authorization).length === 0 ||
      (authorization == null && IsDispatcherLocalstorageExist()) ||
      (authorization.entityType === "DISPATCHER" &&
        IsDispatcherLocalstorageExist())
    ) {
      // reInitDispatcherApp();
      window.location.reload();
    } else {
      let userData = {
        type: postType.LOGOUT,
        dto: {
          data: {},
          error: false,
          errorMessage: "",
        },
      };
      notification.postMessage(userData);
    }
  };

  render() {
    let wrapper_class;
    if (process.env.REACT_APP_COUNTRY === "US") {
      wrapper_class = "parent_us";
    }
    return (
      <div className={`pg reportFeedback ${wrapper_class}`}>
        <div className="content">
          <div className="opps_content">
            <div className="container">
              <div className="text-center">
                <img src={SAD_FACE} title="" alt="" />
                <h2 className="pgTitle oops_title">Oops!</h2>
                <div className="activate_message">Something went wrong.</div>
              </div>
            </div>
          </div>
          <footer className={`bottom_fix gray_bg opps_btn`}>
            <div className="details">
              <button className="btn accept  mb0" onClick={this.closeApp}>
                <span>RELAUNCH</span>
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default ReportFeedback;
