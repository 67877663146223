import * as types from "../../actionTypes";
import * as network from "pr-utility/utils/network";
import {
  technicianCheckIn,
  vehicleDriverAssignment,
  vehicleList,
  technicianLocationUpdate,
  vehicleLocationUpdate,
  dispatcherVehicleList,
} from "pr-utility/utils/APISignature/CheckInAPI";

export function selectTruck(response) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_TRUCK,
      response,
    });
  };
}

export function isLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      bool,
    });
  };
}

export async function sendLocationUpdate(entityUid, type, data) {
  try {
    const response = await network.PUT(
      type === "DRIVER"
        ? technicianLocationUpdate(entityUid)
        : vehicleLocationUpdate(entityUid),
      data
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function getVehicleListAction(uid, limit, offset) {
  try {
    const response = await network.GET(vehicleList(uid, limit, offset));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function vehicleDriverAssignmentAction(vehicleUid, technicianUid) {
  try {
    const response = await network.PUT(
      vehicleDriverAssignment(vehicleUid, technicianUid),
      ""
    );
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function activationCodeEntered(response) {
  return (dispatch) => {
    dispatch({
      type: types.ACTIVATION_CODE_ENTERED,
      response,
    });
  };
}

export function preTripAnswerFromUser(response) {
  return (dispatch) => {
    dispatch({
      type: types.PRE_TRIP_ANS_STATUS,
      response,
    });
  };
}

export function DriverDetailSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.DRIVER_DETAIL_SUCCESS,
      response,
    });
  };
}

export async function TechnicianCheckIn(data) {
  try {
    const response = await network.POST(technicianCheckIn(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function selectDispatcherTruck(response) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_DISPATCHER_TRUCK,
      response,
    });
  };
}

export async function getVehicleListForDispatcher(limit, offset) {
  try {
    const response = await network.GET(dispatcherVehicleList(limit, offset));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}
