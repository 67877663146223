import {
  MAP_PICKUP_LOCATION,
  MAP_JOB_TOW,
  MAP_JOB_FLATTIRE,
  MAP_JOB_KEYREP,
  MAP_JOB_FUEL,
  MAP_JOB_JUMPSTART,
  MAP_JOB_LOCKOUT,
  MAP_JOB_TIREINSTALL,
  MAP_JOB_WINCH,
} from "./imageUtils";

let serviceImageSmall = {
  2001: MAP_JOB_TOW,
  2002: MAP_JOB_LOCKOUT,
  2003: MAP_JOB_JUMPSTART,
  2004: MAP_JOB_FUEL,
  2005: MAP_JOB_FLATTIRE,
  2006: MAP_JOB_TOW, // emergency
  2007: MAP_JOB_WINCH, // winch
  2008: MAP_JOB_KEYREP,
  2009: MAP_JOB_TIREINSTALL, //Tire Install
  2010: MAP_JOB_TOW, //Motorcycle Tow
  2011: MAP_JOB_TOW, //Storage
  2012: MAP_JOB_LOCKOUT, //Home Lockout
  // 2020: Images.pinJobRSA,
  2021: MAP_JOB_TOW,
  2022: MAP_JOB_TOW,
  TOW: MAP_JOB_TOW,
  RSA: MAP_JOB_TOW,
};

export function getSmallServiceImage(serviceId) {
  let sImage = serviceImageSmall[serviceId];
  return sImage ? sImage : MAP_PICKUP_LOCATION;
}

export function getPickupLocationIcon(serviceId, isServiceIcon) {
  if (isServiceIcon) {
    return getSmallServiceImage(serviceId);
  } else {
    return MAP_PICKUP_LOCATION;
  }
}
// export function getDropOffLocationIcon(isServiceIcon) {
//   if (isServiceIcon) {
//     return DROPOFF_LOCATION_ICON;
//   } else {
//     return Images.pinGenericDropOffLocation;
//   }
// }

// export function getDriverLocationIcon(isTruckIcon) {
//   if (isTruckIcon) {
//     return Images.driverTruck;
//   } else {
//     return Images.providerLocationDot;
//   }
// }

// export function getRouteSourceIcon(isGenericIcon) {
//   if (isGenericIcon) {
//     return Images.pinGenericPickUpLocation;
//   } else {
//     return Images.routeDestination;
//   }
// }
