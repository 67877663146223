import * as types from "../../actionTypes";
import {
  providerManagement,
  activateCode,
  appEvent,
  dispatcherAppEvent,
} from "pr-utility/utils/APISignature/ProviderVerificationAPI";
import {
  truckCheckIn,
  getVehicleDetail,
  getVehicleConfig,
} from "pr-utility/utils/APISignature/CheckInAPI";
import * as network from "pr-utility/utils/network";
import client from "pr-utility/config/client";

export async function providerVerify(phoneNumber) {
  try {
    const response = await network.GET(providerManagement(phoneNumber));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function getTruckConfigAction(uid) {
  try {
    const response = await network.GET(getVehicleConfig(uid));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function truckConfigSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_TRUCK_CONFIG_SUCCESS,
      response,
    });
  };
}

export function authDataSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_AUTH_DATA_SUCCESS,
      response,
    });
  };
}

export async function truckCheckInAction(data) {
  try {
    const response = await network.POST(truckCheckIn(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function truckCheckInSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_TRUCK_CHECKIN_SUCCESS,
      response,
    });
  };
}

export function providerVerificationSuccess(response, phoneNumber) {
  return (dispatch) => {
    dispatch({
      type: types.PROVIDER_VERIFICATION_SUCCESS,
      response,
    });
    dispatch({
      type: types.PROVIDER_PHONE_NUMBER,
      phoneNumber,
    });
  };
}
export async function activateApp(data) {
  try {
    const response = await network.POST_NO_TOKEN(activateCode(), data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function activateAppSuccess(data) {
  try {
    let endPoint;
    if (process.env.REACT_APP_COUNTRY === "AU") {
      endPoint = appEvent();
    } else if (process.env.REACT_APP_COUNTRY === "US") {
      endPoint = dispatcherAppEvent();
    }
    const response = await network.POST(endPoint, data);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function getTruckDetailAction(uid) {
  try {
    const response = await network.GET(getVehicleDetail(uid));
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function truckDetailSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.VEHICLE_DETAIL_SUCCESS,
      response,
    });
  };
}

export function SaveCheckinStatus(response) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_CHECKIN_STATUS,
      response,
    });
  };
}

export async function loadConfig(params) {
  try {
    if (client.CONFIG_URL === "") {
      client.setConfigURL("https://dev03-configs.allianzau.urgent.ly/configs");
    }
    let configURL =
      client.CONFIG_URL +
      `?v=${client.API_VERSION}&env=${client.ENVIROMENTUL}&industry=${client.INDUSTRY_TYPE}&device=${client.DEVICE}&os=${client.DEVICE_OS_VERSION}&app=${client.APP}`;
    const response = await network.GETAuth(configURL);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export async function loadConfigKeys(params) {
  try {
    let configURL =
      client.CONFIG_URL +
      `/keys?v=${client.API_VERSION}&env=${client.ENVIROMENTUL}&industry=${client.INDUSTRY_TYPE}&device=${client.DEVICE}&os=${client.DEVICE_OS_VERSION}&app=${client.APP}`;
    const response = await network.GETAuth(configURL);
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}

export function fetchSuccessConfig(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_GET_CONFIG_SUCESS,
      response,
    });
  };
}

export function fetchSuccessConfigKeys(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_GET_CONFIG_KEYS_SUCCESS,
      response,
    });
  };
}

export function isLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      bool,
    });
  };
}
export function resetHome() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_HOME,
    });
  };
}

export function resetDriverData() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_DRIVER_DATA,
    });
  };
}

export function resetAuthorization() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_AUTHORIZATION,
    });
  };
}
export function resetTruck() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_TRUCK_DATA,
    });
  };
}

export function resetEquipment() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_EQUIPEMENT_DATA,
    });
  };
}

export function saveActivationCode(activationCode) {
  return (dispatch) => {
    dispatch({
      type: types.SAVE_ACTIVATION_CODE,
      activationCode,
    });
  };
}
