import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  driverDetail: null,
  allService: null,
  selectedServices: null,
  cancelReason: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.DRIVER_VERIFICATION_SUCCESS: {
      return {
        ...state,
        driverDetail: action.response,
      };
    }
    case types.ALL_SERVICE_LIST_SUCCESS: {
      return {
        ...state,
        allService: action.response,
      };
    }
    case types.SELECTED_SERVICES: {
      return {
        ...state,
        selectedServices: action.response,
      };
    }
    case types.CANCEL_REASON: {
      return {
        ...state,
        cancelReason: action.response,
      };
    }
    default:
      return state;
  }
}
