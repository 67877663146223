const Callbacks = {};

const aEvents = {
  on: (at, id, callback) => {
    return aEvents.listen(at, id, callback);
  },
  listen: (at, id, callback) => {
    /*eslint-disable eqeqeq*/
    if (at == "") {
      return false;
    }
    if (at in Callbacks) {
      Callbacks[at][id] = callback;
    } else {
      Callbacks[at] = {};
      Callbacks[at][id] = callback;
    }
    return id;
  },
  t: (at, data) => {
    return aEvents.trigger(at, data);
  },
  trigger: (at, data) => {
    data = data || "";
    const obj = Callbacks[at];
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        obj[prop](data);
      }
    }
  },
  rm: (at, id) => {
    return aEvents.remove(at, id);
  },
  remove: (at, id) => {
    delete Callbacks[at][id];
  },
  removeAll: (at) => {
    delete Callbacks[at];
  },
};

export const Events = aEvents;
