import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import promise from "redux-promise";
import thunk from "redux-thunk";
import { loadState } from "../localStorage";
import * as reducer from "../reducers";

let middleWare = applyMiddleware(promise, thunk);

if (process.env.NODE_ENV === "development") {
  const logger = createLogger();

  middleWare = applyMiddleware(promise, thunk, logger);
}

export const store = createStore(reducer.default, loadState(), middleWare);
