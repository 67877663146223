/**
 * Event Manger ( Orchestrator)
 */
import { Events } from "pr-utility/event-manager/event";
import { EventList } from "pr-utility/event-manager/event-list";
import * as listener from "pr-utility/event-manager/event-binding";

/**
 * Event listener for all the communication between module
 */
export function registerDefaultEvents() {
  Events.on(EventList.init.login, "module_flow", listener.openLogin);
  Events.on(
    EventList.init.activate_app,
    "module_flow",
    listener.openActivateApp
  );
  Events.on(EventList.login.success, "module_flow", listener.loginSuccess);
  Events.on(EventList.home.settings, "module_flow", listener.openSetting);
  Events.on(EventList.home.check_in, "module_flow", listener.openCheckIn);
  Events.on(EventList.home.checkout, "module_flow", listener.openCheckOut);
  Events.on(EventList.job.job_detail, "module_flow", listener.jobDetail);
  Events.on(EventList.job.job_offer, "module_flow", listener.jobOffer);
  Events.on(
    EventList.job.status_tracker,
    "module_flow",
    listener.statusTracker
  );
  Events.on(EventList.inspection.start, "module_flow", listener.inspection);
  Events.on(
    EventList.chat.dispatcher_chat,
    "module_flow",
    listener.dispatchChat
  );
  Events.on(EventList.chat.customer_chat, "module_flow", listener.customerChat);
  Events.on(EventList.settings.jobList, "module_flow", listener.openJobList);
  Events.on(
    EventList.chat.dispatch_chat_notification,
    "module_flow",
    listener.dispatchChatNotification
  );

  // if (document && window) {
  //   window.testFunction = event => {
  //     listener.nativeMessage(event);
  //   };
  // } else if (document) {
  //   window.testFunction = event => {
  //     listener.nativeMessage(event);
  //   };
  // } else if (window) {
  //   window.testFunction = event => {
  //     listener.nativeMessage(event);
  //   };
  // }
  window.onRNMessageReceive = (event) => {
    listener.nativeMessage(event);
  };
}

// native event listener
