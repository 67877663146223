import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  latitude: null,
  longitude: null,
  accuracy: null,
  speed: null,
  bearing: null,
  address: null,
  city: null,
  state: null,
  street: null,
  zip: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_LOCATION_SUCCESS: {
      return {
        ...state,
        latitude: action.response.latitude,
        longitude: action.response.longitude,
        accuracy: action.response.accuracy,
        speed: action.response.speed,
        bearing: action.response.bearing,
        address: action.response.address,
        city: action.response.city,
        state: action.response.state,
        street: action.response.street,
        zip: action.response.zip,
      };
    }
    default:
      return state;
  }
}
