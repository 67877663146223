import { POST } from "pr-utility/utils/network";
import { dispatcherAppEvent } from "pr-utility/utils/APISignature/ProviderVerificationAPI";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { store } from "../../store";

const EventId = {
  JOB_OFFER_OPENED: 8000,
  JOB_OFFER_ACCEPTED: 8001,
  JOB_OFFER_DECLINED: 8002,
  JOB_OFFER_DECLINED_FAILED: 8003,
  JOB_OFFER_DETAILS_FAILED_TO_LOAD: 8004,
  JOB_OFFER_FAILED_TO_ACCEPT: 8005,
  ON_JOB_DECLINE_PAGE: 8006,
  ON_JOB_CANCEL_PAGE: 8007,
  ON_JOB_DETAIL_PAGE: 8008,
  DRIVER_ON_THE_WAY: 8009,
  DRIVER_ON_SITE: 8010,
  JOB_COMPLETED: 8011,
  JOB_CANCELLED: 8012,
  JOB_CANCELLED_FAILED: 8013,
  JOB_OFFER_RECEIVED_VIA_PUBNUB: 8014,
  JOB_OFFER_RECEIVED_VIA_PUSH: 8015,
};

const EventName = {
  8000: "JOB_OFFER_OPENED",
  8001: "JOB_OFFER_ACCEPTED",
  8002: "JOB_OFFER_DECLINED",
  8003: "JOB_OFFER_DECLINED_FAILED",
  8004: "JOB_OFFER_DETAILS_FAILED_TO_LOAD",
  8005: "JOB_OFFER_FAILED_TO_ACCEPT",
  8006: "ON_JOB_DECLINE_PAGE",
  8007: "ON_JOB_CANCEL_PAGE",
  8008: "ON_JOB_DETAIL_PAGE",
  8009: "DRIVER_ON_THE_WAY",
  8010: "DRIVER_ON_SITE",
  8011: "JOB_COMPLETED",
  8012: "JOB_CANCELLED",
  8013: "JOB_CANCELLED_FAILED",
  8014: "JOB_OFFER_RECEIVED_VIA_PUBNUB",
  8015: "JOB_OFFER_RECEIVED_VIA_PUSH",
};

const EventTypes = {
  PAGE_LOAD: "PAGE_LOAD",
  JOB_RELATED: "JOB_RELATED",
  GENERAL: "GENERAL",
};

const EventType = {
  JOB_OFFER_RECEIVED_VIA_PUSH: EventTypes.JOB_RELATED,
  JOB_OFFER_RECEIVED_VIA_PUBNUB: EventTypes.JOB_RELATED,
  JOB_OFFER_OPENED: EventTypes.JOB_RELATED,
  JOB_OFFER_ACCEPTED: EventTypes.JOB_RELATED,
  JOB_OFFER_DECLINED: EventTypes.JOB_RELATED,
  JOB_OFFER_DECLINED_FAILED: EventTypes.JOB_RELATED,
  JOB_OFFER_DETAILS_FAILED_TO_LOAD: EventTypes.JOB_RELATED,
  JOB_OFFER_FAILED_TO_ACCEPT: EventTypes.JOB_RELATED,
  ON_JOB_DETAIL_PAGE: EventTypes.PAGE_LOAD,
  DRIVER_ON_THE_WAY: EventTypes.JOB_RELATED,
  DRIVER_ON_SITE: EventTypes.JOB_RELATED,
  JOB_COMPLETED: EventTypes.JOB_RELATED,
  ON_JOB_CANCEL_PAGE: EventTypes.PAGE_LOAD,
  ON_JOB_DECLINE_PAGE: EventTypes.PAGE_LOAD,
  JOB_CANCELLED: EventTypes.JOB_RELATED,
  JOB_CANCELLED_FAILED: EventTypes.JOB_RELATED,
};

const EntityTypes = {
  Driver: "Driver",
  Truck: "Truck",
  Dispatcher: "Dispatcher",
};

/**
 * Event Log on server for each event success
 * @param jobNumber - Job number
 * @param eventId - Job's event Id
 * @param eventType - Specific event type
 * @param caseId - Job Case DTO Id
 * @constructor
 */

const LogAllEvent = ({
  jobID: jobNumber,
  eventId,
  eventType,
  caseId = undefined,
}) => {
  const { Authorization, Home } = store.getState();

  const { entityUid: entityId, entityType } = Authorization.authorization;
  const { activeJobList } = Home;

  const finalCaseId = caseId || (activeJobList?.caseDTO?.id ?? "");

  const eventValue = EventName[eventId];

  const finalEventType =
    eventType || (EventType[eventValue] ?? EventTypes.GENERAL);

  let data = {
    jobNumber,
    entityId,
    entityType,
    eventId, // should be mapped like confluence
    eventType: finalEventType,
    eventValue,
    caseId: finalCaseId,
    product: "dispatcher_app",
    source: "app", // Hard coded as of now
  };

  let endPoint = dispatcherAppEvent();

  try {
    POST(endPoint, data);
  } catch (err) {
    const logData = {
      data: {
        error: JSON.stringify({
          data: JSON.stringify(err),
          message: "/event api failure",
        }),
      },
    };

    sendErrorLog(logData);
  }
};

export { LogAllEvent, EntityTypes, EventName, EventId };
