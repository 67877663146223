import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";
import { deepMergeCustom } from "pr-utility/utils/utils";

const initialState = Immutable({
  providerVerification: null,
  providerPhoneNumber: null,
  activeJobList: null,
  codeEntered: null,
  tokenSent: null,
  jobOfferAccepted: false,
  lastActionPerformedJob: [],
  numberOfNotification: 0,
  dispatchChatNotificationCount: 0,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.PROVIDER_VERIFICATION_SUCCESS: {
      return {
        ...state,
        providerVerification: action.response,
      };
    }
    case types.PROVIDER_PHONE_NUMBER: {
      return {
        ...state,
        providerPhoneNumber: action.phoneNumber,
      };
    }
    case types.ON_ADD_ACTIVE_JOB_LIST: {
      var currentState = action.response;
      if (state.activeJobList !== null) {
        currentState = JSON.parse(JSON.stringify(state.activeJobList));
        deepMergeCustom(action.response, currentState);
      }
      return {
        ...state,
        activeJobList: currentState,
      };
    }
    case types.ON_CLEAR_ACTIVE_JOB: {
      return {
        ...state,
        activeJobList: null,
      };
    }
    case types.ACTIVATION_CODE_ENTERED: {
      return {
        ...state,
        codeEntered: action.response,
      };
    }
    case types.JOB_OFFER_ACCEPT: {
      return {
        ...state,
        jobOfferAccepted: action.bool,
      };
    }
    case types.RESET_HOME: {
      return {
        ...initialState,
      };
    }
    case types.TOKEN_SENT: {
      return {
        ...state,
        tokenSent: action.response,
      };
    }
    case types.LAST_ACTION_PERFOMED_JOB: {
      return {
        ...state,
        lastActionPerformedJob: [...action.response],
      };
    }
    case types.ADD_ONE_NOTIFICATION: {
      return {
        ...state,
        numberOfNotification: state.numberOfNotification + 1,
      };
    }
    case types.CLEAR_NOTIFICATION: {
      return {
        ...state,
        numberOfNotification: 0,
      };
    }

    case types.ADD_ONE_DISPATCH_CHAT_NOTIFICATION: {
      return {
        ...state,
        dispatchChatNotificationCount: state.dispatchChatNotificationCount + 1,
      };
    }

    case types.SAVE_DISPATCH_CHAT_NOTIFICATION: {
      return {
        ...state,
        dispatchChatNotificationCount: action.response,
      };
    }

    case types.CLEAR_DISPATCH_CHAT_NOTIFICATION: {
      return {
        ...state,
        dispatchChatNotificationCount: 0,
      };
    }

    default:
      return state;
  }
}
