import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import SelectTruckPage from "./screens/SelectTruck/SelectTruck";
import SelectEquipmentPage from "./screens/SelectEquipment/SelectEquipment";
import DriverVerificationPage from "./screens/DriverVerification/DriverVerification";
import ActivateAppPage from "./screens/ActivateApp/ActivateApp";
import PoweredByPage from "./screens/Splash-PoweredBy/PoweredBy";
import UserPage from "./screens/User/User";
import MobileVerificationPage from "./screens/User/MobileVerification";
import InitAppPage from "./screens/InitApp/InitApp";
import InitDispatcherPage from "./screens/InitDispatcherApp/InitDispatcherApp";

class LoginShell extends Component {
  render() {
    const InitApp = InitAppPage;
    const DriverVerification = DriverVerificationPage;
    const SelectTruck = SelectTruckPage;
    const SelectEquipment = SelectEquipmentPage;
    const ActivateApp = ActivateAppPage;
    const PoweredBy = PoweredByPage;
    const User = UserPage;
    const MobileVerification = MobileVerificationPage;
    const InitDispatcherApp = InitDispatcherPage;

    return (
      <Switch>
        {/* <Route exact path="/" component={loginShell.ProviderVerification} /> */}
        <Route exact path="/" component={InitApp} />
        <Route exact path="/powered-by" component={PoweredBy} />
        <Route exact path="/activate-app" component={ActivateApp} />
        <Route
          exact
          path="/mobile-verification"
          component={MobileVerification}
        />
        <Route exact path="/user" component={User} />
        {/* <Route exact path="/not-me" component={NotMe} /> */}
        <Route exact path="/select-truck" component={SelectTruck} />
        <Route exact path="/select-equipment" component={SelectEquipment} />
        <Route exact path="/profile" component={DriverVerification} />
        <Route exact path="/dispatch" component={InitDispatcherApp} />
      </Switch>
    );
  }
}

export default withRouter(LoginShell);
