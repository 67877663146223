import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  authorization: {},
  initAppData: null,
  volumeLevel: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.ON_AUTH_DATA_SUCCESS: {
      return {
        ...state,
        authorization: {
          ...action.response,
          expirationTime:
            new Date().getTime() + action.response.expiresIn * 1000,
        },
      };
    }

    case types.ON_RESTORE_AUTH_DATA_SUCCESS: {
      return {
        ...state,
        authorization: {
          ...action.response,
        },
      };
    }

    case types.INIT_APP_DATA: {
      return {
        ...state,
        initAppData: action.response,
      };
    }

    case types.UPDATE_VOLUME_LEVEL: {
      return {
        ...state,
        volumeLevel: action.response,
      };
    }

    case types.REFRESH_TOKEN: {
      return {
        ...state,
        authorization: {
          ...action.response,
          expirationTime:
            new Date().getTime() + action.response.expiresIn * 1000,
        },
      };
    }

    case types.SET_EXPIRATION_TIME: {
      return {
        ...state,
        authorization: {
          ...state.authorization,
          expirationTime:
            new Date().getTime() + action.response.expiresIn * 1000,
        },
      };
    }

    case types.RESET_AUTHORIZATION: {
      return {
        ...state,
        authorization: null,
        expirationTime: null,
      };
    }

    case types.SAVE_ACTIVATION_CODE: {
      return {
        ...state,
        authorization: {
          ...state.authorization,
          code: action.activationCode,
        },
      };
    }

    default:
      return state;
  }
}
