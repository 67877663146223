import MAP_PIN_ICON from "../images/Truck_icon.svg";
import DRIVER_ICON from "../images/driver.png";
import URGENTLY_ICON from "../images/PoweredBy_Color.svg";
import PICKUP_LOCATION_ICON from "../images/ic_Pick-Up.svg";
import DROPOFF_LOCATION_ICON from "../images/icon_business.png";
import JOB_TOW from "../images/Pin_Job_Tow.svg";
import JOB_FLATTIRE from "../images/Pin_Job_FlatTire.svg";
import JOB_KEYREP from "../images/Pin_KeyReplace.svg";
import JOB_FUEL from "../images/Pin_Job_Fuel.svg";
import JOB_ENGIN from "../images/Pin_Job_Engine.svg";
import JOB_JUMPSTART from "../images/Pin_Job_JumpStart.svg";
import JOB_LOCKOUT from "../images/Pin_Job_Lockout.svg";
import JOB_TIREINSTALL from "../images/Pin_Job_TireInstall.svg";
import JOB_WINCH from "../images/Pin_Job_Winch.svg";
import JOB_DROPOFF from "../images/Pin-Dropoff.svg";

export const MAP_PIN = MAP_PIN_ICON;
export const DRIVER_PIN = DRIVER_ICON;
export const URGENTLY_LOGO = URGENTLY_ICON;
export const MAP_PICKUP_LOCATION = PICKUP_LOCATION_ICON;
export const MAP_DROPOFF_LOCATION = DROPOFF_LOCATION_ICON;
export const MAP_JOB_TOW = JOB_TOW;
export const MAP_JOB_FLATTIRE = JOB_FLATTIRE;
export const MAP_JOB_KEYREP = JOB_KEYREP;
export const MAP_JOB_FUEL = JOB_FUEL;
export const MAP_JOB_ENGIN = JOB_ENGIN;
export const MAP_JOB_JUMPSTART = JOB_JUMPSTART;
export const MAP_JOB_LOCKOUT = JOB_LOCKOUT;
export const MAP_JOB_TIREINSTALL = JOB_TIREINSTALL;
export const MAP_JOB_WINCH = JOB_WINCH;
export const MAP_JOB_DROPOFF = JOB_DROPOFF;
