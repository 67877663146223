import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as actions from "./Actions";
import * as InitAppActions from "../InitApp/Actions";
import { restoreAuthDataSuccess } from "../InitApp/Actions";
import client from "pr-utility/config/client";
import notification from "pr-utility/utils/notification";
import {
  IsDispatcherLocalstorageExist,
  getDispatcherLocalstorage,
  removeDispatcherLocalstorage,
  setDispatcherLocalstorage,
} from "pr-utility/utils/utils";
import { postType } from "pr-utility/utils/postMessage";
import { sendErrorLog } from "pr-utility/utils/notificationUtil";
import { FormattedMessage } from "react-intl";
import AlertMessage from "../CommonComponent/AlertMessage";

class InitDispatcherApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadView: false,
      showAlert: false,
      alertData: {},
      dispatcherData: [],
    };
  }

  componentDidMount() {
    if (
      window.location.hash.indexOf("browser=1") !== -1 &&
      window.location.hash.indexOf("accessToken=") !== -1 &&
      window.location.hash.indexOf("jobNumber=") !== -1 &&
      window.location.hash.indexOf("refreshToken=") !== -1 &&
      window.location.hash.indexOf("entityUid=") !== -1
    ) {
      removeDispatcherLocalstorage();
      this.startApp();
    } else {
      let getLocalstorageData = getDispatcherLocalstorage();
      if (
        getLocalstorageData &&
        getLocalstorageData.accessToken &&
        getLocalstorageData.refreshToken &&
        getLocalstorageData.scope &&
        getLocalstorageData.entityType &&
        getLocalstorageData.entityUid &&
        getLocalstorageData.jobNumber
      ) {
        this.startApp(getLocalstorageData);
      } else {
        this.showAlertModal(false, {
          data: {
            error: <FormattedMessage id="activate.invalid_link" />,
          },
        });
      }
    }
  }
  startApp = async (dispatcherData) => {
    await this.loadConfigs();
    let authorization;
    if (dispatcherData) {
      authorization = {
        accessToken: dispatcherData.accessToken,
        refreshToken: dispatcherData.refreshToken,
        scope: "offline_access",
        entityType: "DISPATCHER",
        entityUid: dispatcherData.entityUid,
      };
    } else {
      authorization = {
        accessToken: this.getHashValue("accessToken"),
        refreshToken: this.getHashValue("refreshToken"),
        scope: "offline_access",
        entityType: "DISPATCHER",
        entityUid: this.getHashValue("entityUid"),
      };
    }
    client.setAuthToken(authorization.accessToken);
    this.props.dispatch(await restoreAuthDataSuccess(authorization));
    this.getDispatcherDetail();
  };
  loadConfigs = async () => {
    let URLENV;
    if (
      window.location.href.includes("qa-") ||
      window.location.href.includes("dev01-") ||
      window.location.href.includes("localhost") ||
      window.location.href.includes("10.12.46.135")
    ) {
      URLENV = "dev01";
    } else if (
      window.location.href.includes("dev02-") ||
      window.location.href.includes("dev-")
    ) {
      URLENV = "dev02";
    } else {
      URLENV = "prod";
    }
    await this.handleAppInit(URLENV);
    await this.loadConfigData();
    await this.loadConfigKeysData();
    client.setHostURL(this.props.AppConfig?.configs?.config?.["api.url.v4"]);
  };

  handleAppInit = async (URLENV) => {
    if (URLENV === "dev01") {
      client.setConfigURL("https://dev-configs.urgent.ly/configs");
      client.setRefreshURL(
        "https://" + URLENV + "-auth.urgent.ly/provider-entities/auth/refresh"
      );
    } else if (URLENV === "dev02") {
      client.setConfigURL("https://dev02-configs.urgent.ly/configs");
      client.setRefreshURL(
        "https://" + URLENV + "-auth.urgent.ly/provider-entities/auth/refresh"
      );
    } else {
      client.setConfigURL("https://configs.urgent.ly/configs");
      client.setRefreshURL(
        "https://auth.urgent.ly/provider-entities/auth/refresh"
      );
    }
    client.setApp("dispatcherApp");
    client.setDeviceOsVersion(23);
    client.setDevice("Android");
    if (["dev01", "dev02", "dev03"].indexOf(URLENV) !== -1) {
      client.setEnvirontmentString("dev");
    } else {
      client.setEnvirontmentString(URLENV);
    }
    client.setIndustry("Roadside");
    client.setAPIVersion("3.0.0");
    client.setCountryCode("+1");
    client.setTruckAction(false);
    client.setCountryISOCode("US");
  };

  loadConfigData = async () => {
    this.props.dispatch(actions.isLoading(true));

    const response = await InitAppActions.loadConfig();

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      this.props.dispatch(
        InitAppActions.fetchSuccessConfig(response.data.data[0])
      );

      if (
        response.data &&
        response.data.data[0] &&
        response.data.data[0].config
      ) {
        let hostUrl = response.data.data[0].config["api.url.v4"];
        let hostUrlV3 = response.data.data[0].config["api.url"];
        client.setHostURL(hostUrl);
        client.setHostURLV3(hostUrlV3);
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.loadConfigData);
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
    }
  };

  loadConfigKeysData = async () => {
    this.props.dispatch(actions.isLoading(true));

    const response = await InitAppActions.loadConfigKeys();

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      this.props.dispatch(
        InitAppActions.fetchSuccessConfigKeys(response.data.data[0].config)
      );
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.loadConfigKeysData);
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
    }
  };
  showAlertModal = (netWorkError, response, callback, params) => {
    let alertData;
    if (netWorkError) {
      if (response && response.code && response.code === "ECONNABORTED") {
        alertData = {
          header: <FormattedMessage id="common.timeout_header" />,
          body: <FormattedMessage id="common.timeout_message" />,
          rightText: <FormattedMessage id="common.cancel" />,
          leftOnClick: () => {
            this.setState({ showAlert: false });
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          },
          leftText: <FormattedMessage id="common.retry" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: "",
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.close" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    } else {
      if (response) {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: response.data ? (
            response.data.error ? (
              response.data.error
            ) : (
              <FormattedMessage id="profile.error_msg" />
            )
          ) : (
            <FormattedMessage id="profile.error_msg" />
          ),
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            if (process.env.REACT_APP_COUNTRY === "US") {
              //alert("clicked ok");
              let userData = {
                type: postType.LOGOUT,
                dto: {
                  data: {},
                  error: false,
                  errorMessage: "",
                },
              };
              notification.postMessage(userData);
            }
            this.setState({ showAlert: false });
          },
        };
      } else {
        alertData = {
          header: <FormattedMessage id="profile.oops" />,
          body: <FormattedMessage id="profile.error_msg" />,
          rightText: <FormattedMessage id="profile.ok" />,
          rightOnClick: () => {
            this.setState({ showAlert: false });
          },
        };
      }
    }
    this.setState({ showAlert: true, alertData: alertData });
  };
  getHashValue(key) {
    var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
    return matches ? matches[1] : null;
  }

  getDispatcherDetail = async () => {
    this.props.dispatch(actions.isLoading(true));

    const response = await actions.getDispatcher();

    this.props.dispatch(actions.isLoading(false));

    if (response && Number(response.status) === 200) {
      if (response.data && response.data.data) {
        let dispatcherData = response.data.data;
        this.setState({
          dispatcherData,
        });
        this.props.dispatch(
          await actions.setDispatcherDetail(dispatcherData[0])
        );
        if (dispatcherData[0].countryConfigurations) {
          this.props.dispatch(
            await InitAppActions.providerProfileSuccess(
              dispatcherData[0].countryConfigurations
            )
          );
        }

        const { dispatcherDetail, Authorization } = this.props;

        let Location = {
          latitude: dispatcherDetail.hqLatitude,
          longitude: dispatcherDetail.hqLongitude,
          address: "",
          street: dispatcherDetail.street,
          city: dispatcherDetail.city,
          state: dispatcherDetail.state,
          zip: dispatcherDetail.zip,
        };

        this.props.dispatch(InitAppActions.updateLocationSuccess(Location));

        this.props.dispatch(actions.authDataSuccess(Authorization));

        let jobNumber;
        if (!IsDispatcherLocalstorageExist()) {
          jobNumber = this.getHashValue("jobNumber");
        } else {
          jobNumber = getDispatcherLocalstorage().jobNumber;
        }

        let dispatchLocalData = {
          ...Authorization,
          jobNumber: jobNumber,
        };
        setDispatcherLocalstorage(dispatchLocalData);

        this.props.dispatch(await actions.setDispatcherJobNumber(jobNumber));
        this.props.history.push("/home");
      } else {
        this.showAlertModal(false, {
          data: {
            error: <FormattedMessage id="activate.invalid_link" />,
          },
        });
      }
    } else if (response.code === "ECONNABORTED") {
      sendErrorLog(JSON.stringify(response));
      this.showAlertModal(true, response, this.getDispatcherDetail);
    } else if (response === undefined) {
      sendErrorLog(response);
      this.showAlertModal(true);
    } else {
      sendErrorLog(response);
      this.showAlertModal(false, response);
    }
  };

  render() {
    return (
      <div className="pg">
        <AlertMessage
          showAlert={this.state.showAlert}
          {...this.state.alertData}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { dispatcherDetail } = state.DispatcherData;

  return {
    dispatcherDetail,
    AppConfig: state.AppConfig,
    Authorization: state.Authorization.authorization,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators(actions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InitDispatcherApp)
);
