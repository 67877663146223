import * as types from "../../actionTypes";
import { getDispatcherDetail } from "pr-utility/utils/APISignature/CheckInAPI";
import * as network from "pr-utility/utils/network";

export function setDispatcherJobNumber(response) {
  return (dispatch) => {
    dispatch({
      type: types.SET_DISPATCHER_JOB_NUMBER,
      response,
    });
  };
}
export async function getDispatcher(response) {
  try {
    const response = await network.GET(getDispatcherDetail());
    return response;
  } catch (error) {
    if (!error.response) return error;
    else {
      return error.response;
    }
  }
}
export async function setDispatcherDetail(response) {
  return (dispatch) => {
    dispatch({
      type: types.SET_DISPATCHER_DETAIL,
      response,
    });
  };
}
export function authDataSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.ON_RESTORE_AUTH_DATA_SUCCESS,
      response,
    });
  };
}

export function isLoading(bool) {
  return (dispatch) => {
    dispatch({
      type: types.IS_LOADING,
      bool,
    });
  };
}
