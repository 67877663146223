import * as types from "../../actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  access_token: null,
  job_number: null,
  dispatcherDetail: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_DISPATCHER_JOB_NUMBER: {
      return {
        ...state,
        job_number: action.response,
      };
    }
    case types.SET_DISPATCHER_DETAIL: {
      return {
        ...state,
        dispatcherDetail: action.response,
      };
    }
    default:
      return state;
  }
}
