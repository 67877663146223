import notification from "./notification";
import { postType } from "./postMessage";

export const sendErrorLog = (data, errorMessage) => {
  if (data === undefined) {
    data = {
      data: {
        error:
          "We are having trouble right now Please try Again after sometime.",
      },
    };
  }
  let logData = {
    type: postType.ERROR_INFO,
    isjobdto: false,
    dto: {
      data: data,
      error: true,
      errorMessage: errorMessage || "",
    },
  };
  notification.postMessage(logData);
};
