import React from "react";
import { LOADER_IMG } from "pr-utility/images/index";

const Loader = (props) => {
  let title = "";
  if (props.title) {
    title = props.title;
  }
  return (
    <div className={`loaderbg ${props.loaded ? "hide" : "show"}`}>
      <div className="loader_content">
        <div className="loaderText">{title}</div>
        <div className="loaderimg">
          <img src={LOADER_IMG} title="" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
