/** @enum {string} */
export const GAEvents = {
  JOB_OFFER_RECEIVED: "JOB_OFFER_RECEIVED",
  JOB_OFFER_ACCEPTED: "JOB_OFFER_ACCEPTED",
  JOB_OFFER_DECLINED: "JOB_OFFER_DECLINED",
  JOB_COMPLETED: "JOB_COMPLETED",
  DRIVER_ON_THE_WAY: "DRIVER_ON_THE_WAY",
  DRIVER_ON_SITE: "DRIVER_ON_SITE",
  DRIVER_TOW_UNDERWAY: "DRIVER_TOW_UNDERWAY",
};

/**
 * Send event to GA
 * @param eventName {GAEvents}
 */
export const sendGAEvent = (eventName) => {
  if (window.gtag) {
    window.gtag("event", eventName);
  }
};
